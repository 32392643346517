import React, { useEffect, useState } from "react";
import "./Toolbar.css";
import { useDispatch } from "react-redux";
import { logout } from "./../login/Index";
import logo from "./../../assets/images/logo.png";
import { ReactComponent as Person } from "./../../assets/svg/person_white.svg";
import { ReactComponent as Logout } from "./../../assets/svg/logout_white.svg";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { setSiteSelected } from "./../dashboard/Index";

let sites = [];
const Toolbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [loginUser, setLoginUser] = useState({
    firstName: "",
    lastName: "",
    role: "",
  });

 /**
  * This function is used to logout
  */
  const logoutHandler = () => {
    sessionStorage.setItem("jwtToken", "");
    sessionStorage.setItem("validateOTPResponse", "");
    sessionStorage.setItem("sites", "");
    sessionStorage.clear();
    navigate("/login");
    dispatch(logout(""));
  };

/**
 * This function is used to get the role from session
 */
  const getRole = () => {
    const roleObjWithout = sessionStorage.getItem("validateOTPResponse");
    sites = JSON.parse(sessionStorage.getItem("sites"));

    if (
      roleObjWithout !== "" &&
      roleObjWithout !== {} &&
      roleObjWithout !== undefined
    ) {
      let roleObj = JSON.parse(roleObjWithout);
      if (roleObj && roleObj.roles && roleObj.roles.length > 0) {
        let role = roleObj && roleObj.roles && roleObj.roles[0].name;


        if (role === "SuperAdmin" || role === "all") {
          setIsSuperUser(true);
          dispatch(setSiteSelected("all"));
          setLoginUser({ ...loginUser, role });
        } else {
          setIsSuperUser(false);
          setLoginUser({ ...loginUser, role });
          dispatch(setSiteSelected(role));
        }
      }
    }
  };

  useEffect(() => {
   
    const validateOTPResponse =
      // JSON.parse(
      sessionStorage.getItem("validateOTPResponse");
    // );
    if (
      validateOTPResponse !== "" &&
      validateOTPResponse !== {} &&
      validateOTPResponse !== undefined
    )
      setLoginUser(JSON.parse(validateOTPResponse));
    getRole();
  }, []);

  //This function is used to transform the date
  function getCurrentDate() {
    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.getMonth();
    const year = newDate.getFullYear();
    const convertDate = new Date(year, month, date); // 2009-11-10
    const month1 = convertDate.toLocaleString("default", { month: "short" });

    return month1 + " " + date + "," + " " + year;
  }
  return (
    <div className="toolbar-container">
      <span className="toolbar-logo">
        <img className="toolbar-logo-img" src={logo} alt="" />
      </span>
      <div style={{ minWidth: "90%", display: "flex", flexDirection: "row" }}>
        <span className="toolbar-mid-component">
          <span style={{ fontWeight: "bold", paddingLeft: "10px" }}>
            Epilepsy Monitoring Portal
          </span>
          <span data-testid="current-date">{getCurrentDate()}</span>
        </span>
        <span className="toolbar-right-component">
          <div className="left">
            {!isSuperUser ? (
              <span style={{ paddingLeft: "10px" }} data-testid="site-user">
               {loginUser.role}
              </span>
            ) : (
              <Form.Select
                size="sm"
                data-testid="site-superUser"
                className="site_dropdown"
                onChange={(e) => {
                  e.preventDefault();
                  dispatch(setSiteSelected(e.target.value));
                }}
              >
                  {
                    sites.map(site => {
                      return (
                        <option value={site.name}>
                          {site.name.charAt(0).toUpperCase() + site.name.slice(1)}
                        </option>
                      );
                    })
                  }
                {/* <option value="all" defaultChecked>
                  All Sites
                </option>
                <option value="site 1">Site 1</option>
                <option value="site 2">Site 2</option>
                <option value="site 3">Site 3</option> */}
              </Form.Select>
            )}
          </div>
          <span className="right">
            <span>
              <Person></Person>
              {loginUser &&
                loginUser.firstName &&
                loginUser.firstName + ""}{" "}
              <span className="last-name">
                {loginUser && loginUser.lastName && loginUser.lastName + ""}
              </span>
              .
            </span>
            <Logout data-testid="logout" onClick={logoutHandler} />
          </span>
        </span>
      </div>
    </div>
  );
};

export default Toolbar;
