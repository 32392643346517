import rootReducer from "./reducers/index";
// import {
//   persistStore,
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import { PersistGate } from "redux-persist/integration/react";

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import createSagaMiddleware from "redux-saga";
import rootSaga from "./saga/RootSaga";
const sagaMiddleware = createSagaMiddleware();
// const middleware = [sagaMiddleware];
// const defaultmid = (...getDefaultMiddleware) =>
//   getDefaultMiddleware({
//     thunk: false,
//     serializableCheck: {
//       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//     },
//   });
const middleware = [
  // defaultmid,
  // ...getDefaultMiddleware({ thunk: false }),
  // (getDefaultMiddleware) =>
  ...getDefaultMiddleware({
    thunk: false,
    // serializableCheck: {
    //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    // },
  }),
  sagaMiddleware,
];

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

// const rootReducer1 = persistReducer(persistConfig, rootReducer);
const rootReducer1=rootReducer
const store = configureStore({
  reducer: { rootReducer1 },
  middleware: middleware,
  // middleware
});
sagaMiddleware.run(rootSaga);
// rootSaga(sagaMiddleware)
export default store;
