import React, { useEffect, useState } from "react";
var initialState = {
  patientId: "",
  loading: false,
  error: null,
  patientsData: [],
  createPatientResponse: { status: "", message: "" },
  siteSelected: "",
  selectedPatientId: "",
  tostrObj: {
    show: false,
    delay: 3000,
    onClose: false,
    header: "",
    message: "",
  },
};

const dashboarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PATIENT_ID_REQUESTED":
      return { ...state, loading: true, sendOTPflag: false };
    case "GET_PATIENT_ID_SUCCESS":
      return {
        ...state,
        loading: false,
        patientId: action.patientIdResponse.data.patientId,
      };
    case "GET_PATIENT_ID_FAILED":
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case "GET_PATIENTS_REQUESTED":
      return { ...state, loading: true };
    case "GET_PATIENTS_SUCCESS":
      let patientsData = action.patientsData;
    
      action.patientsData.forEach((element) => {
      
        var diffInMiliSecond = 0;
        element.patientSleepCycle &&
          element.patientSleepCycle.forEach((time) => {
            diffInMiliSecond =
              diffInMiliSecond +
              Math.abs(new Date(time.wakeUpTime) - new Date(time.sleepTime)) /
                1000;
          });
        let diffInMilliSeconds = diffInMiliSecond;
        const hours = Math.floor(diffInMilliSeconds / 3600);
        diffInMilliSeconds -= hours * 3600;
       
        // calculate minutes
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;
     

        let difference = "";
        difference +=
          hours === 0 || hours === 1 ? `${hours}.` : `${hours}.`;
        difference +=
      minutes < 10  ? `0${minutes}hrs` : `${minutes}hrs`;

    
        element.epilepsyHistory.forEach((history) => {
          
          history.avgSleepCycle = difference;
          
        });
       
      });
      return {
        ...state,
        loading: false,
        patientsData: patientsData,
      };
    case "GET_PATIENTS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case "CREATE_PATIENT_REQUESTED":
      return {
        ...state,
        loading: true,
        createPatientResponse: { status: "", message: "" },
      };
    case "CREATE_PATIENT_SUCCESS":
      return {
        ...state,
        loading: false,
        createPatientResponse: action.createPatientResponse,
      };
    case "CREATE_PATIENT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case "LOGOUT":
      return { state: initialState };
    case "SET_SITE_SELECTED":
     
      return {
        ...state,
        siteSelected: action.payload,
      };

    case "SET_PATIENT_ID_SELECTED":
      
      return {
        ...state,
        selectedPatientId: action.payload,
      };

    default:
      return state;
  }
};

export default dashboarReducer;
