import React, { useEffect, useState } from "react";
import "./ParticipantDetailsComponent.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { setToastr } from "./../../login/Index";
import {  useDispatch } from "react-redux";
export const ParticipantDetailsComponent = (props) => {
  const [data, setData] = useState([]);
  const url = process.env.REACT_APP_SERVER_BASE_URL;
  const [patientId, setPatientId] = useState("");
  const [value, setValue] = useState();
  const [id, setID] = useState(0);
  const [notes, setNotes] = useState("");
  const dispatch = useDispatch();
  const tokenStr = JSON.parse(sessionStorage.getItem("jwtToken"));
  const headers = {
    Authorization: `Bearer ${tokenStr}`,
  };

  
/*   useEffect(() => {  
    setData(props.patientData)
  },[ props  && props.patientData ]) */

  /**
   * This function is used to get patients detail data.
   * @param {*} val
   */

  const getData = async (val) => {
    const res = await axios
      .get(url + "detail/patient/" + val, {
        headers,
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
          // client never received a response, or request never left
        } else {
          // anything else
          console.log(err.message);
        }
      });

    setData(res.data.data.deviceData);
    if (res.data.data.notes && res.data.data.notes.notes != null) {
      setNotes(res.data.data.notes.notes);
      setID(res.data.data.notes.id);
    } else {
      setNotes("");
      setID(0);
    }
  };

  /**
   * This function will get executed on save click to save notes
   * @param {*} e
   */
  const saveNotes = async (e) => {
    e.preventDefault();
    const res = await axios
      .post(
        url + "detail/addNotes",
        { patientId: patientId, notes: notes, id: id },
        {
          headers,
        }
      )
      .catch((error) => {
        console.log(error);
      });
    if (res.status === 201) {
      dispatch(
        setToastr([
          {
            show: true,
            delay: 3000,
            onClose: false,
            header: res.data.status,
            message: res.data.message,
            color: "success",
          },
        ])
      );
    } else {
      dispatch(
        setToastr([
          {
            show: true,
            delay: 3000,
            onClose: false,
            header: "Failure",
            message: "Please try again.",
            color: "warning",
          },
        ])
      );
    }
  };

  /**
   * This function is used to set patient id on patient select
   * @param {*} e
   */
  const onPatientChangeHandler = (e) => {
    let val = e.target.value;
    setNotes("");
    setPatientId(val);

    props.patientData.map((element) => {
      if (val === element.patientInfo.patientId) {
        setValue(element.patientInfo.mobileNumber);
      }
    });
    if (val !== "") {
      getData(val);
      setNotes("");
      setData([]);
      setID(0);
    }
  };

  return (
    <Card className="table_top">
      <Card.Header className="table_header">
        <Row>
          <Col>
            <h1> PARTICIPANT DETAILS</h1>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body className="mt-3">
        <Row className="g-3 mb-2">
          <Col md={6}>
            <FloatingLabel
              controlId="floatingSelectGrid"
              label="Participant UID"
            >
              <Form.Select
                aria-label="Floating label select example"
                data-testid="dropdown"
                onChange={onPatientChangeHandler}
              >
                <option value="" defaultChecked hidden>
                  Select..
                </option>
                {props.patientData &&
                  props.patientData.map((patient, index) => {
                    return (
                      <option value={patient.patientInfo.patientId} key={index}>
                        {patient.patientInfo.patientId}
                      </option>
                    );
                  })}
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col md={6}>
            <FloatingLabel controlId="floatingInputGrid" label="Mobile Number">
              <Form.Control
                type="text"
                disabled={patientId === ""}
                placeholder="Mobile Number"
                data-testid="MobileNumber-input"
                value={value}
                style={{
                  border: "none",
                  backgroundColor: "rgba(43, 102, 241, 0.15)",
                  height: "48px",
                }}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <div style={{ height: "100px", marginTop: "10px" }}>
          <Table
            className="main_table"
            hover
            size="sm"
            responsive
            data-testid="main-table"
          >
            <thead>
              <tr>
                <th>Device ID</th>
                <th>Description</th>
                <th>Date Issued</th>
                <th>Alerts</th>
              </tr>
            </thead>
            <tbody>
            {data &&
                data.map((e, index) => {
                  return (<> 
                   { console.log(e,index)}
                    <tr key={index}>
                      
                      <td>{e.deviceId}</td>
                      <td>{e.description}</td>
                      <td>{e.dateIssued}</td>
                      <td>{e.alerts}</td>
                    </tr>
                    </>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <Row>
          <div className="notes">
            <FloatingLabel
              controlId="floatingTextarea"
              label="Notes"
              className={
                patientId === "" && "none"
                  ? "mb-3 input_textarea_disable "
                  : "mb-3 input_textarea "
              }
            >
              <Form.Control
                value={notes}
                maxLength="250"
                as="textarea"
                data-testid="notes-input"
                placeholder="Leave a comment here"
                disabled={patientId === ""}
                // style={{ height: "90px" }}
                style={{
                  height: "90px",
                  border: patientId === "" && "none",
                  backgroundColor:
                    patientId === "" && "rgba(43, 102, 241, 0.15)",
                }}
                onKeyUp={(e) => {
                  e.preventDefault();
                  if (notes.length >= 250) {
                    dispatch(
                      setToastr([
                        {
                          show: true,
                          delay: 3000,
                          onClose: false,
                          header: "Warning",
                          message: "Maximum character count reached.",
                          color: "warning",
                        },
                      ])
                    );
                  }
                }}
                onChange={(e) => {
                  e.preventDefault();

                  setNotes(e.target.value);
                }}
              />
            </FloatingLabel>
          </div>
        </Row>

        <div className="action_buttons">
          <Button
            className="m-2"
            type="button"
            variant="primary"
            onClick={(e) => saveNotes(e)}
            disabled={patientId === "" || notes == ""}
          >
            Save Notes
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};
