const intialState = {
  star: 0,
  jwtToken: "",
  serverBaseUrl:process.env.REACT_APP_SERVER_BASE_URL
};

const changeTheNumber = (state = intialState, action) => {
  switch (action.type) {
    case "INCREMENT":
      return { ...state, star: state.star + 1 };
    case "DECREMENT":
      return { ...state, star: state.star - 1 };
    //   return state - 1;
    case "SAVEJWT":
        return { ...state, jwtToken:action.payload};
        case "SERVERBASEURL":
        return { ...state, serverBaseUrl:action.payload};
    default:
      return state;
  }
};
export default changeTheNumber;
