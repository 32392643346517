import { put, takeLatest } from "redux-saga/effects";
const url = process.env.REACT_APP_SERVER_BASE_URL;

export function* handleGetPatientID(action) {
  try {
    const tokenStr = action.payload;
    const patientIdResponse = yield fetch(url + "createPatientId", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenStr}`,
      },
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });

   
    yield put({
      type: "GET_PATIENT_ID_SUCCESS",
      patientIdResponse: patientIdResponse,
      sendOTPflag: true,
    });
  } catch (err) {
    yield put({
      type: "GET_PATIENT_ID_FAILED",
      message: err.message,
      sendOTPflag: false,
    });
  }
}

export function* watcherGetPatientId() {
  yield takeLatest("GET_PATIENT_ID_REQUESTED", handleGetPatientID);
}

export function* handleGetPatients(action) {
  if (
    action.payload.siteSelected == "" ||
    action.payload.siteSelected === undefined ||
    action.payload.siteSelected === null
  ) {
    return;
  }
  try {
    const tokenStr = action.payload.jwtToken;
    const siteSelected = action.payload.siteSelected;
  
    const patientsData = yield fetch(url + "patients/clinic/" + siteSelected, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenStr}`,
      },
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });

    yield put({
      type: "GET_PATIENTS_SUCCESS",
      patientsData: patientsData.data,
      sendOTPflag: true,
    });
  } catch (err) {
    yield put({
      type: "GET_PATIENTS_FAILED",
      message: err.message,
      sendOTPflag: false,
    });
  }
}

export function* watcherGetPatients() {
  yield takeLatest("GET_PATIENTS_REQUESTED", handleGetPatients);
}

export function* handleCreatePatient(action) {
  try {
    const tokenStr = action.payload.jwtToken;
    const createPatientResponse = yield fetch(url + "addPatient", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenStr}`,
      },
      body: JSON.stringify(action.payload.createPatientObj),
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });

    yield put({
      type: "CREATE_PATIENT_SUCCESS",
      createPatientResponse: createPatientResponse,
      message: createPatientResponse.status,
    });
  } catch (err) {
    yield put({
      type: "CREATE_PATIENT_FAILED",
      message: err.message,
    });
  }
}

export function* watcherCreatePatient() {
  yield takeLatest("CREATE_PATIENT_REQUESTED", handleCreatePatient);
}
