import React, { useState, useEffect } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { useSelector, useDispatch } from "react-redux";
const ToasterComponenet = (props) => {
  const [showA, setShowA] = useState(false);
  const [position, setPosition] = useState("top-end");
  var reduxToastrArray = useSelector(
    (state) => state.rootReducer1.loginReducer.toastrObj
  );
  const toggleShowA = () => setShowA(!showA);
  const [toasterArray, setToasterArray] = useState(reduxToastrArray);
 
  useEffect(() => {
   
    var toasterArray = [];
    reduxToastrArray &&
      reduxToastrArray.forEach((element) => {
        if (element.show) toasterArray.push(element);
       
      });
    setToasterArray(toasterArray);
  
  }, [reduxToastrArray && reduxToastrArray]);

  return (
    <div>
      <ToastContainer position={position}>
        {toasterArray &&
          toasterArray.map((toasterObj, index) => {
            return (
              <Toast
                key={index}
                show={toasterObj.show}
                delay={3000}
                autohide={true}
                bg={toasterObj.color}
                onClose={(e, index) => {
                 
                  setToasterArray([]);
                }}
              >
                <Toast.Header>
                  <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                  />
                  <strong className="me-auto">{toasterObj.header}</strong>
                </Toast.Header>
                <Toast.Body>
                  <small className="text-white">{toasterObj.message}</small>
                </Toast.Body>
              </Toast>
            );
          })}
      </ToastContainer>
    </div>
  );
};
export default ToasterComponenet;
