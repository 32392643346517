import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from 'react-dom/client';
import store from "./store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
// import { PersistGate } from "redux-persist/integration/react";
// import {
//   persistStore

// } from 'redux-persist'
const root = ReactDOM.createRoot(document.getElementById("root"));
// let persistor = persistStore(store);
 // createRoot(container!) if you use TypeScript


root.render(
  
    <Provider store={store}>
  {/*     {/* <PersistGate loading={null} persistor={persistor}> */}
      {/* <React.StrictMode> */} 
      <Router>
        <App />
      </Router>
      {/* </React.StrictMode> */}
      {/* </PersistGate> */}
    </Provider>
  
  
  // document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
