import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./SpinnerComponent.css";
const SpinnerComponent = (props) => {
  return props && props.loginSpinner === "loginSpinner" ? (
    <div  className="overlay2">
      <Spinner
        animation="border"
        style={{ width: "5rem", height: "5rem" }}
        variant="primary"
        role="status"
        data-testid="spinner2"
      >
       
      </Spinner>
    </div>
  ) : (
    <div className="overlay">
      <Spinner
        animation="border"
        style={{ width: "5rem", height: "5rem" }}
        variant="primary"
        role="status"
        data-testid="spinner1"
      >
       
      </Spinner>
    </div>
  );
};

export default SpinnerComponent;
