import React from "react";
import "./LeftMenu.css";
import {  useState } from "react";
import { ReactComponent as Enroll } from "./../../assets/svg/enroll_white.svg";
import { ReactComponent as Report } from "./../../assets/svg/report_white.svg";


const LeftMenu = ({ menuSelected, childToParent }) => {
  const active = true;
  const [menu, setMenuSelected] = useState(menuSelected);

  return (
    <div className="left-menu-container"  data-testid="left-menu-container">
      <div
        className={`left-sub-menu ${menu == "dashboard" ? "active" : ""}`}
        onClick={() => {
          setMenuSelected("dashboard");
          childToParent("dashboard");
        }}
      >
        <span className="logo">
          <Enroll></Enroll>
        </span>
        <span className="label">Enroll</span>
      </div>
      <div
        className={`left-sub-menu ${menu == "report" ? "active" : ""}`}
        onClick={() => {
          setMenuSelected("report");
          childToParent("report");
        }}
      >
        <span className="logo">
          <Report></Report>
        </span>
        <span className="label">Report</span>
      </div>

     
    </div>
  );
};

export default LeftMenu;
