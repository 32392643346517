/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import "./Login.css";
import logo from "./../../assets/images/logo.png";
import Final_logo from "./../../assets/images/Neurava_Final.png";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { sendOTP, validateOTP, saveJWT, setToastr } from "./Index";
import { useNavigate } from "react-router-dom";
import ToasterComponenet from "./../subcomponents/toaster/ToasterComponent";
import SpinnerComponent from "./../subcomponents/spinner/SpinnerComponent";

const Login = () => {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("+91");
 //const [mobileNumber, setMobileNumber] = useState("+1"); //+1 for final build
  const [showToastr, setShowToastr] = useState(false);
  const isAuthenticated = useSelector(
    (state) => state.rootReducer1.loginReducer.isAuthenticated
  );
  const [loading, setLoading] = useState(false);
  const [otp, setOTP] = useState("");
  const [otpFlag, setOTPFlag] = useState(false);
  const dispatch = useDispatch();
  const [toasterObj, setToasterObj] = useState({
    show: false,
    delay: 3000,
    onClose: false,
    header: "",
    message: "",
  });

  const [over, setOver] = useState(false);
  const [[h, m, s], setTime] = useState([0, 2, 0]);
  const [showTimer, setShowTimer] =useState(false)
  const tick = () => {
    if ( over) return;
    if (h === 0 && m === 0 && s === 0){setOver(true); 
      setShowTimer(false)}
    else if (m === 0 && s === 0) {
      setTime([h - 1, 59, 59]);
    } else if (s == 0) {
      setTime([h, m - 1, 59]);
    } else {
      setTime([h, m, s - 1]);
    }
  };

  const reset = () => {
    setTime([0, 2, 0]);

    setOver(false);
  };
  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const url = useSelector(
    (state) => state.rootReducer1.changeTheNumber.serverBaseUrl
  );
  const loginStore = useSelector((state) => state.rootReducer1.loginReducer);

  const submitLogin = async (e) => {
    e.preventDefault();
 
    const user = {
      phoneNo: mobileNumber,
      deviceOS:"WEB"
      // otp: otp,
    };
    // dispatch(saveJWT(data.jwtToken));

    // dispatch(sendOTP(user));

    {
      setLoading(true);
      try {
     
        fetch(url + "sms/sendOTP", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${tokenStr}`
          },
          body: JSON.stringify(user),
        })
          .then((response) => response.json())
          .then((json) => {
          

            setLoading(false);
            if (json.status === "1") {
              setOTPFlag(true);
              setToasterObj({
                show: true,
                delay: 3000,
                onClose: false,
                header: json.message,
                message: json.data,
                color: "success",
              });
              dispatch(
                setToastr([
                  {
                    show: true,
                    delay: 3000,
                    onClose: false,
                    header: json.message,
                    message: json.data,
                    color: "success",
                  },
                ])
              );
              setShowToastr(true);
              setShowTimer(true)
              reset(); //reset countDown timer
              sessionStorage.setItem("loginTime", new Date().getTime());
            } else {
              setToasterObj({
                show: true,
                delay: 3000,
                onClose: false,
                header: json.message,
                message: json.data,
                color: "danger",
              });
              dispatch(
                setToastr([
                  {
                    show: true,
                    delay: 3000,
                    onClose: false,
                    header: json.message,
                    message: json.data,
                    color: "danger",
                  },
                ])
              );
              setShowToastr(true);
            }
          })
          .catch((error) => {
            setLoading(false);

            setToasterObj({
              show: true,
              delay: 3000,
              onClose: false,
              header: "Network error",
              message: "Please try again later.",
              color: "danger",
            });
            dispatch(
              setToastr([
                {
                  show: true,
                  delay: 3000,
                  onClose: false,
                  header: "Network error",
                  message: "Please try again later.",
                  color: "danger",
                },
              ])
            );
            setShowToastr(true);
            throw error;
          });
      } catch (err) {
        console.log(err);
        setLoading(false);
        setToasterObj({
          show: true,
          delay: 3000,
          onClose: false,
          header: "Network error",
          message: "Please try again later.",
          color: "danger",
        });
        dispatch(
          setToastr([
            {
              show: true,
              delay: 3000,
              onClose: false,
              header: "Network error",
              message: "Please try again later.",
              color: "danger",
            },
          ])
        );

        setShowToastr(true);
      }
    }
  };

  useEffect(() => {
    if (loginStore.jwtToken != "") {
  
      sessionStorage.setItem("jwtToken", JSON.stringify(loginStore.jwtToken));
      sessionStorage.setItem(
        "validateOTPResponse",
        JSON.stringify(loginStore.validateOTPResponse)
      );

      navigate("/dashboard");
    }
  }, [isAuthenticated]);

  const validateOTPs = async (e) => {
    
    setShowTimer(true)
    const validateOTPRequest = {
      phoneNo: mobileNumber,
      deviceOS : "WEB",
      otp: otp,
    };
   

    // eslint-disable-next-line no-lone-blocks
    {
      setLoading(true);
      try {
      

        fetch(url + "sites", {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }).then(siteResponse => siteResponse.json()).then(siteJson => {
          siteJson.data = siteJson.data.filter(site => {
            if (site.name != 'patient' && site.name != 'caregiver') {
              return site
            }
          })
          console.log("Sites are :: ", siteJson.data)
          sessionStorage.setItem(
            "sites",
            JSON.stringify(siteJson.data)
          );
        })

        fetch(url + "sms/validateOTP", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${tokenStr}`
          },
          body: JSON.stringify(validateOTPRequest),
        })
          .then((response) => response.json())
          .then((json) => {
         

            setLoading(false);
            if (json.status == "SUCCESS") {
              setOTPFlag(true);
              setToasterObj({
                show: true,
                delay: 3000,
                onClose: false,
                header: "Success",
                message: json.message,
                color: "success",
              });
              dispatch(
                setToastr([
                  {
                    show: true,
                    delay: 3000,
                    onClose: false,
                    header: "Success",
                    message: json.message,
                    color: "success",
                  },
                ])
              );
              sessionStorage.setItem(
                "jwtToken",
                JSON.stringify(json.data.jwtToken)
              );
              sessionStorage.setItem(
                "validateOTPResponse",
                JSON.stringify(json.data.loginDetails)
              );
              setShowToastr(true);
              navigate("/dashboard");
            } else {
              setToasterObj({
                show: true,
                delay: 3000,
                onClose: false,
                header: "Failure",
                message: json.message,
                color: "danger",
              });
              dispatch(
                setToastr([
                  {
                    show: true,
                    delay: 3000,
                    onClose: false,
                    header: "Failure",
                    message: json.message,
                    color: "danger",
                  },
                ])
              );
              setShowToastr(true);
            }
          })
          .catch((error) => {
            setLoading(false);
console.log(error);
            setToasterObj({
              show: true,
              delay: 3000,
              onClose: false,
              header: "Network error",
              message: "Please try again later.",
              color: "danger",
            });
            dispatch(
              setToastr([
                {
                  show: true,
                  delay: 3000,
                  onClose: false,
                  header: "Network error",
                  message: "Please try again later.",
                  color: "danger",
                },
              ])
            );
            setShowToastr(true);
            throw error;
          });
      } catch (err) {
        console.log(err);
        setLoading(false);
        setToasterObj({
          show: true,
          delay: 3000,
          onClose: false,
          header: "Network error",
          message: "Please try again later.",
          color: "danger",
        });
        dispatch(
          setToastr([
            {
              show: true,
              delay: 3000,
              onClose: false,
              header: "Network error",
              message: "Please try again later.",
              color: "danger",
            },
          ])
        );

        setShowToastr(true);
      }
    }
  };

  const [sendOTPs, setSendOTPs] = useState(
    useSelector((state) => state.rootReducer1.loginReducer.sendOTPResponse)
  );

  useEffect(() => {
    if (sendOTPs != undefined) {

      if (sendOTPs.status == "1") {
        setShowToastr(true);
      }
    }
  }, [sendOTPs]);
  let i = 0;
  useEffect(() => {
    if (i == 0) {
      if (loginStore.jwtToken != "" && loginStore.jwtToken != null) {
        const jwtTokenTemp = JSON.parse(
          sessionStorage.getItem("jwtToken") + ""
        );

        dispatch(saveJWT(jwtTokenTemp));
      } else {
        
        {
          let jwtTokenTemp = "";
          if (
            sessionStorage.getItem("jwtToken") != undefined &&
            sessionStorage.getItem("jwtToken") != null &&
            sessionStorage.getItem("jwtToken") != ""
          ) {
            jwtTokenTemp = JSON.parse(sessionStorage.getItem("jwtToken") + "");
           
            navigate("/dashboard");
          } else {
            
    

      
          }
    
        }
      }
     
    }
    i = i + 1;
  }, []);

  function isNumber(evt) {
    evt = evt ? evt : window.event;

    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  return (
    <div className="main-container">
      {loading && <SpinnerComponent loginSpinner={"loginSpinner"} />}
      {showToastr && <ToasterComponenet toasterObj={toasterObj} />}
      {/* {showToastr && <ToasterComponenet toasterObj={toasterObj} />} */}
      <div className="left-container">
        <img className="image-container" src={Final_logo} alt="logo"></img>
      </div>
      <div className="right-container">
        <Card style={{ width: "500px", border: "0px solid rgba(0,0,0,.125)" }}>
          <Card.Body>
            <Card.Title className="card-title">Sign In</Card.Title>
            <Form className="login-form" action="" onSubmit={submitLogin}>
              <Form.Group className="mb-3" controlId="mobileNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  // type="number"
                  data-testid="phNum-input"
                  placeholder="Enter mobile number"
                  value={mobileNumber}
                   onChange={(e) =>
                    setMobileNumber(
                      e.target.value
                        .replace(/[a-zA-Z!@#\$%\^\&*\;:"' )\(=._-]/, "")
                        .slice(0, 13)
                    )
                  } 
                    //for +1 in final build
                    /* onChange={(e) =>
                      setMobileNumber(
                        e.target.value
                          .replace(/[a-zA-Z!@#\$%\^\&*\;:"' )\(=._-]/, "")
                          .slice(0, 12)
                      )
                    } */


                  onKeyPress={(event) => {
                    return isNumber(event);
                  }}
                />

                {/* <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text> */}
              </Form.Group>
              <>
                {otpFlag && (
                  <Form.Group className="mb-3" controlId="OTP">
                    <Form.Label>OTP</Form.Label>
                    <Form.Control
                      data-testid="otp-input"
                      type="otp"
                      placeholder="Enter otp"
                      value={otp}
                      onChange={(e) => setOTP(e.target.value)}
                    />{" "}
                  </Form.Group>
                )}
                { showTimer && <p style={{ fontSize: "10px" }}>
                {" "}
                Resend Otp after{" "}
                {`${m.toString().padStart(2, "0")}:${s
                  .toString()
                  .padStart(2, "0")}`}
              </p>}
              </>

              <div className="action-container">
                {!otpFlag ? (
                  <Button
                    style={{ width: "170px" }}
                    variant="primary"
                    type="button"
                    onClick={submitLogin}
                   disabled={!(mobileNumber.length >= 11)}
                    //disabled={!(mobileNumber.length >= 12)} //for +1 final
                  >
                    Send OTP
                  </Button>
                ) : (
                  <>
                    {" "}
                    <Button
                      style={{ width: "170px" }}
                      variant="primary"
                      type="button"
                      // type="submit"
                      disabled={!over || !(mobileNumber.length >= 11)}
                      // disabled={!over || !(mobileNumber.length >= 12)}
                      onClick={submitLogin}
                    >
                      Resend OTP
                    </Button>
                    <Button
                    role="button"
                      style={{ width: "170px", marginLeft: "15px" }}
                      variant="primary"
                      onClick={(e) => validateOTPs(e.target.value)}
                      type="button"
                    >
                      Login
                    </Button>
                  </>
                )}
              </div>
             
            </Form>
          </Card.Body>
        
        </Card>
        {/* <h1>Sign In</h1>
        <Button variant="primary">Button #1</Button> */}
      </div>
    </div>
  );
};

export default Login;
