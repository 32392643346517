import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import "./Report.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { setToastr } from "../login/Index";
import SpinnerComponent from "../subcomponents/spinner/SpinnerComponent";

const Report = () => {
  const url = process.env.REACT_APP_SERVER_BASE_URL;
  const [band, setBand] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [patientData, setPatientData] = useState();
  const [btnEnable, setBtnEnable] = useState(false);
  const dispatch = useDispatch();
  const [selectedPatient, setSelectedPatient] = useState();
  const [patientName, setPatientName] = useState();
  const [patientId, setPatientId] = useState();
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [neckFileData, setNeckFileData] = useState([]);


  const aws_url = "https://0er3x6y7da.execute-api.us-west-2.amazonaws.com/?"

  const siteSelected = useSelector(
    (state) => state.rootReducer1.dashboarReducer.siteSelected
  );
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    clearErrors,
    trigger,
  } = useForm();

 /*  useEffect(() => {
    getData();
  }, []); */
  useEffect(() => {
    if (siteSelected !== "") {
      getData();
    }
  }, [siteSelected && siteSelected]);

  const parallelApiCalls = async () => {
    try {
      const responses = await Promise.all([armbandFn(), neckbandFn()]);

    

      if (responses) {
        responses.map((val, i) => {
        
          if (i === 0) {
            var name = "ArmBand";
            blobToFile(val, name);
          } else if (i === 1) {
            var Bname = "NeckBand";
            blobToFile(val, Bname);
          }
        });
        setLoading(false);
      }
    } catch (err) {
      console.log(err.message);
      dispatch(
        setToastr([
          {
            show: true,
            delay: 3000,
            onClose: false,
            header: "Error",
            message: err.message,
            color: "warning",
          },
        ])
      );
      setLoading(false);
      if (err.response) {
        console.log(err.response.status);
        console.log(err.response.data);
        setLoading(false);
      }
      setLoading(false);
    }
  };

  const blobToFile = (data, bandName) => {
    setLoading(true);

    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${patientName} ${bandName}.csv`);

    // Append to html link element page
    document.body.appendChild(link);
    setLoading(false);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const onSubmit = async () => {
    setLoading(true);
    const res = await axios
      .get(
        url +
          `report/patient/${patientId}?startDate=${startDate} 00:00:00.0000&endDate=${endDate} 23:59:59.0000&bandType=all`,
        {
          headers,
        }
      )
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
          // client never received a response, or request never left
        } else {
          // anything else
          console.log(err.message);
        }
      });
    setBtnEnable(true);
    setLoading(false);
   
  };

  const StartDateCustomInput = ({ value, onClick }) => (
    <FloatingLabel
      controlId="floatingSelectGrid"
      label="Start Date"
      data-testid="startDate-input"
    >
      <Form.Control
        autoComplete="off"
        defaultValue={modifyDate(startDate)}
        type="text"
        placeholder="Start Date"
        onClick={onClick}
        disabled={!selectedPatient}
        style={{
          border: selectedPatient ? "" : "none",
          backgroundColor: selectedPatient ? "" : "rgba(43, 102, 241, 0.15)",
        }}
      />
    </FloatingLabel>
  );

  const EndDateCustomInput = ({ value, onClick }) => (
    <FloatingLabel
      controlId="floatingSelectGrid"
      label="End Date"
      data-testid="endDate-input"
    >
      <Form.Control
        autoComplete="off"
        defaultValue={modifyDate(endDate)}
        type="text"
        placeholder="End Date"
        onClick={onClick}
        disabled={!startDate}
        style={{
          border: startDate ? "" : "none",
          backgroundColor: startDate ? "" : "rgba(43, 102, 241, 0.15)",
        }}
      />
    </FloatingLabel>
  );

  const modifyDate = (date1) => {
    if (date1 !== "" && date1 !== undefined) {
      const dateCharArray = date1 && date1.split("-");

      const monthArray = [
        { key: "00", value: "" },
        { key: "01", value: "Jan" },
        { key: "02", value: "Feb" },
        { key: "03", value: "Mar" },
        { key: "04", value: "Apr" },
        { key: "05", value: "May" },
        { key: "06", value: "Jun" },
        { key: "07", value: "Jul" },
        { key: "08", value: "Aug" },
        { key: "09", value: "Sept" },
        { key: "10", value: "Oct" },
        { key: "11", value: "Nov" },
        { key: "12", value: "Dec" },
      ];

      return (
        monthArray[+dateCharArray[1]].value +
        " " +
        dateCharArray[2] +
        "," +
        " " +
        dateCharArray[0]
      );
    } else return "";
  };
  function getCurrentDate() {
    const newDate = new Date();
    const date = newDate.getDate();
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const yesterDayDate = yesterday.getDate()

    const month = newDate.getMonth();
    const year = newDate.getFullYear();
    const convertDate = new Date(year, month, date); // 2009-11-10
    const month1 = convertDate.toLocaleString("default", { month: "short" });

    // return month1 + " " + yesterDayDate + "," + " " + year;   //restricting user from selecting today's day
   return month1 + " " + date + "," + " " + year;
  }

  

  const tokenStr = JSON.parse(sessionStorage.getItem("jwtToken"));

  const headers = {
    Authorization: `Bearer ${tokenStr}`,
  };

  const getData = async () => {
    if (siteSelected !== "") {
      const res = await axios
        .get(url + "patients/clinic/" + siteSelected, {
          headers,
        })
        .catch((err) => {
          if (err.response) {
            // client received an error response (5xx, 4xx)
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
            // client never received a response, or request never left
          } else {
            // anything else
            console.log(err.message);
          }
        });

      setPatientData(res.data.data);
    }
  };

  //download both files for all band selection

  const neckBandHandler = () => {
    setLoading(true);
    fetch(
      url +
        `report/patient/exportCSV/test/${patientId}?startDate=${startDate}&endDate=${endDate}&bandType=neckband`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/csv",
          Authorization: `Bearer ${tokenStr}`,
        },
      }
    )
      .then((response) => {
        response.blob();
       
      })
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${patientName}_neckband.csv`);

        // Append to html link element page
        document.body.appendChild(link);
        setLoading(false);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
          // client never received a response, or request never left
        } else {
          // anything else
          console.log(err.message);
        }
      });
  };
  const armbandFn = async () => {
    return await fetch(
      aws_url+`patient_id=${patientId}&band_type=armband&start_date=${startDate}&end_date=${endDate}`,

     // url + `report/patient/exportCSV/test/${patientId}?startDate=${startDate}&endDate=${endDate}&bandType=armband`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/csv",
          Authorization: `Bearer ${tokenStr}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
     
        return blob;
      });
  };

  const neckbandFn = async () => {
    return await fetch(
      aws_url+`patient_id=${patientId}&band_type=neckband&start_date=${startDate}&end_date=${endDate}`,
    //  url +       `report/patient/exportCSV/test/${patientId}?startDate=${startDate}&endDate=${endDate}&bandType=neckband`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/csv",
          Authorization: `Bearer ${tokenStr}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
       
        return blob;
      });
  };

  const armBandHandler = () => {
    setLoading(true);
    fetch(
      url +
        `report/patient/exportCSV/test/${patientId}?startDate=${startDate}&endDate=${endDate}&bandType=armband`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/csv",
          Authorization: `Bearer ${tokenStr}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Patient_${patientId}_armband.csv`);

        // Append to html link element page
        document.body.appendChild(link);

        setLoading(false);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {


        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
          // client never received a response, or request never left
        } else {
          // anything else
          console.log(err.message);
        }
      });
  };

  //new logic---------------------------------------------------------------------------------------------------\\
  var neckBandFile = `${patientName} NeckBand.csv`;
  var armBandFile = `${patientName} ArmBand.csv`;


const bandUrlFn = async (bandType) =>{
  try {
    // endpoint points to Lambda that generates workbook and presigned URL
     const res = await fetch(aws_url+`patient_id=${patientId}&band_type=${bandType}&start_date=${startDate}&end_date=${endDate}`, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
      
       },
       
     })

     // URL returned from my POST call
     const response = await res.text()
console.log("band",bandType,response)
 window.location.replace(response)
// setDownloadLink(downloadurl)


   }
   catch (err) {
     console.error(err)
   }

}

const armBandUrl = async()=>{
  try {
    // endpoint points to Lambda that generates workbook and presigned URL
     const res = await fetch(aws_url+`patient_id=${patientId}&band_type=armband&start_date=${startDate}&end_date=${endDate}`, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
      
       },
       
     })

     // URL returned from my POST call
     const {body} = await res.json()
console.log("arm",body)

return body
// setDownloadLink(downloadurl)


   }
   catch (err) {
     console.error(err)
   }


 
}


const neckBandUrl = async()=>{

  
  try {
    // endpoint points to Lambda that generates workbook and presigned URL
     const res = await fetch(aws_url+`patient_id=${patientId}&band_type=neckband&start_date=${startDate}&end_date=${endDate}`, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
      
       },
       
     })

     // URL returned from my POST call
     const {body} = await res.json()
console.log("neck",body)

return body
// setDownloadLink(downloadurl)
setLoading(false)
   }
   catch (err) {
     console.error(err)
   }


}


  const AllBandApiCalls = async () => {
    try {
      const responses = await Promise.all([neckBandUrl(),armBandUrl()]);

    

      if (responses) {
        console.log("responses",responses)
       


        for(var i=0; i<2 ;i++){
          console.log("vlaue of i",i)
          console.log("vlaueresponses[i]",)
        


          var count=0;



          for (var key in responses[i]) {
            if (responses[i].hasOwnProperty(key)) {
                console.log(key + " -> " + responses[i][key]);

  
                if(responses[i][key]=="null"){
                  count=count+1
                  console.log("if block3",count)
                  if(count===1 || count<1 && !(count>1) && i===0){
                    //window.alert("no data found")
                    dispatch(
                      setToastr([
                        {
                          show: true,
                          delay: 3000,
                          onClose: false,
                          header: "No Data",
                          message: "CSV Data Empty",
                          color: "warning",
                        },
                      ])
                    );
                  }
                 // _.once(noCsvAlert)
                 
                }else{
                  window.open(responses[i][key], "_blank");
                 console.log("else block")
                }

                
            }
          }

         // window.location.replace(responses[i])
        }
        setLoading(false);
      }
    } catch (err) {
      console.log(err.message);
      dispatch(
        setToastr([
          {
            show: true,
            delay: 3000,
            onClose: false,
            header: "Error",
            message: err.message,
            color: "warning",
          },
        ])
      );
      setLoading(false);
      if (err.response) {
        console.log(err.response.status);
        console.log(err.response.data);
        setLoading(false);
      }
      setLoading(false);
    }
  };


 
  

  const handleDownload = async (e) => {
   
  
    if (band === "all") {

      console.log("allband")
      setLoading(true);

      AllBandApiCalls()
  

    } else if (band === "armband") {
   
      setLoading(true);

     
  try {
    // endpoint points to Lambda that generates workbook and presigned URL
     const res = await fetch(aws_url+`patient_id=${patientId}&band_type=armband&start_date=${startDate}&end_date=${endDate}`, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
      
       },
       
     })

     // URL returned from POST call
     const {body} = await res.json()
console.log("arm1",body)


var count=0;
for (var key in body) {
  if (body.hasOwnProperty(key)) {
      console.log(key + " -> " + body[key]);
      
      if(body[key]=="null"){
        count=count+1
        console.log("if block3",count)
        if(count===1 || count<1 && !(count>1)){
          //window.alert("no data found")
          dispatch(
            setToastr([
              {
                show: true,
                delay: 5000,
                onClose: false,
                header: "No Data",
                message: "CSV Data Empty",
                color: "warning",
              },
            ])
          );
        }
       // _.once(noCsvAlert)
       
      }else{
        window.open(body[key], "_blank");
       console.log("else block")
      }
   
  }
}



//window.location.replace(response) 
// setDownloadLink(downloadurl)
setLoading(false)
   }
   catch (err) {
     console.error(err)
   }


    } else if (band === "neckband") {
      
      setLoading(true);
    


      try {
        // endpoint points to Lambda that generates workbook and presigned URL
         const res = await fetch(aws_url+`patient_id=${patientId}&band_type=neckband&start_date=${startDate}&end_date=${endDate}`, {
           method: 'POST',
           headers: {
             'Content-Type': 'application/json',
          
           },
           
         })
    
         // URL returned from my POST call
         const {body} = await res.json()
         console.log("neck",body)
         
         
         var count=0;
for (var key in body) {
  if (body.hasOwnProperty(key)) {
      console.log(key + " -> " + body[key]);
      
      if(body[key]=="null"){
        count=count+1
        console.log("if block3",count)
        if(count===1 || count<1 && !(count>1)){
          //window.alert("no data found")
          dispatch(
            setToastr([
              {
                show: true,
                delay: 5000,
                onClose: false,
                header: "No Data",
                message: "CSV Data Empty",
                color: "warning",
              },
            ])
          );
        }
       // _.once(noCsvAlert)
       
      }else{
        window.open(body[key], "_blank");
       console.log("else block")
      }
   
  }
}

    // setDownloadLink(downloadurl)
    setLoading(false)
       }
       catch (err) {
         console.error(err)
       }
    
    }



    
  }


const hrefLink= "https://0er3x6y7da.execute-api.us-west-2.amazonaws.com/?patient_id=jai&band_type=armband&start_date=2022-10-01&end_date=2022-10-02"
  
const bandHandler = (filename) => {
    fetch(
      aws_url+`patient_id=${patientId}&band_type=${band}&start_date=${startDate}&end_date=${endDate}`,
    //  url +`report/patient/exportCSV/test/${patientId}?startDate=${startDate}&endDate=${endDate}&bandType=${band}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/csv",
          Authorization: `Bearer ${tokenStr}`,
        },
      }
    )
     .then((response) => response.blob())
      .then((blob) => {

        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);

        // Append to html link element page
        document.body.appendChild(link);

        setLoading(false);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(
          setToastr([
            {
              show: true,
              delay: 3000,
              onClose: false,
              header: "Error",
              message: err.message,
              color: "warning",
            },
          ])
        );
        setLoading(false);

        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log(err.response);
          setLoading(false);

        } else if (err.request) {
          console.log(err.request);
          setLoading(false);

          // client never received a response, or request never left
        } else {
          // anything else
          console.log(err.message);
          setLoading(false);

        }
      });
  };

  const downloadHandler = () => {
   


    setLoading(true);
  
    if (band === "all") {
      //neckBandHandler()

      parallelApiCalls();
    } else if (band === "armband") {
      bandHandler(armBandFile);
    } else if (band === "neckband") {
      bandHandler(neckBandFile);
    }
  };

  const singleToDoubleDigitConverter = (input) => {
    if (input < 10) {
      return "0" + input;
    } else {
      return input;
    }
  };

  //old logic

  /* const downloadHandler = async()=>{
  setLoading(true);
  const res = await axios
  .get(
    url +
      `report/patient/exportCSV/test/${patientId}?startDate=${startDate}&endDate=${endDate}&bandType=${band}`,
    {
      headers,
    }
  )
  .catch((err) => {
    if (err.response) {
      // client received an error response (5xx, 4xx)
      console.log(err.response);
    } else if (err.request) {
      console.log(err.request);
      // client never received a response, or request never left
    } else {
      // anything else
      console.log(err.message);
    }
  });
  setLoading(false);
  console.log(res.blob)
} */

  const getArmBandCsvData = async () => {
    setFileData([]);
    setLoading(true);
    const res = await axios
      .get(
        url +
          `report/patient/${patientId}?startDate=${startDate} 00:00:00.0000&endDate=${endDate} 23:59:59.0000&bandType=armband`,
        {
          headers,
        }
      )
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
          // client never received a response, or request never left
        } else {
          // anything else
          console.log(err.message);
        }
      });
    setLoading(false);

    dispatch(
      setToastr([
        {
          show: true,
          delay: 3000,
          onClose: false,
          header: "Reports Generated",
          message:
            "Reports have been generated successfully.Click on download button!",
          color: "success",
        },
      ])
    );
    setBtnEnable(true);

    // modifiedCsvData(res.data.data);
  };

  const getNeckBandCsvData = async () => {
    setNeckFileData([]);
    setLoading(true);
    const res = await axios
      .get(
        url +
          `report/patient/${patientId}?startDate=${startDate} 00:00:00.0000&endDate=${endDate} 23:59:59.0000&bandType=neckband`,
        {
          headers,
        }
      )
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
          // client never received a response, or request never left
        } else {
          // anything else
          console.log(err.message);
        }
      });
    setLoading(false);

    setBtnEnable(true);

    // modifiedNeckCsvData(res.data.data);
  };

  const selectedPatientHandler = (e) => {
    setStartDate();
    setEndDate();

    setSelectedPatient(e.target.value);
    let targetPatient = e.target.value;

    patientData.map((patient, index) => {
      if (targetPatient === patient.patientInfo.patientId) {
        setPatientName(patient.patientInfo.patientName);
        setPatientId(patient.patientInfo.patientId);
      }

      setBtnEnable(false);
    });
  };

  const dataArray = [];

  const modifiedCsvData = (res) => {
    if (res !== undefined || res !== null) {
      const csvFileData = [
        {
          patientName: res.patientInfo.patientName,
          patientId: res.patientInfo.patientId,
          Mobile: res.patientInfo.mobileNumber,
          deviceId: res.deviceId,

          emg: res.rawData.emg,
          flex: res.rawData.flex,

          accX: res.rawData.accX,
          accY: res.rawData.accY,
          accZ: res.rawData.accZ,

          gyX: res.rawData.gyX,
          gyY: res.rawData.gyY,
          gyZ: res.rawData.gyZ,

          alerts: res.patientAlerts,
        },
      ];

      //creating rows for csv with specific format

      csvFileData.forEach((item, index) => {
        if (index === 0) {
          dataArray.push({
            patientId: "",
            patientName: "",
            Mobile: "",
            DeviceID: "",
            empty1: "",
            alerts: "",
            emgTimeHeader: "TimeStamp",
            emgDataHeader: "Data",

            empty2: "",
            flexTimeHeader: "TimeStamp",
            flexDataHeader: "Data",
            empty3: "",

            accXTimeHeader: "TimeStamp",
            accXDataHeader: "Data",
            empty4: "",

            accYTimeHeader: "TimeStamp",
            accYDataHeader: "Data",
            empty5: "",

            accZTimeHeader: "TimeStamp",
            accZDataHeader: "Data",
            empty6: "",

            gyXTimeHeader: "TimeStamp",
            gyXDataHeader: "Data",
            empty7: "",
            gyYTimeHeader: "TimeStamp",
            gyYDataHeader: "Data",
            empty8: "",
            gyZTimeHeader: "TimeStamp",
            gyZDataHeader: "Data",
          });
        }

        dataArray.push({
          patientId: item.patientId,
          patientName: item.patientName,
          Mobile: item.Mobile,
          DeviceID: item.deviceId,
          empty1: "",
          alerts: "",

          emgTimeHeader: item.emg && item.emg[0] && item.emg[0].timeStamp,
          emgDataHeader: item.emg && item.emg[0] && item.emg[0].data,

          empty2: "",
          flexTimeHeader: item.flex && item.flex[0] && item.flex[0].timeStamp,
          flexDataHeader: item.flex && item.flex[0] && item.flex[0].data,
          empty3: "",
          accXTimeHeader: item.accX && item.accX[0] && item.accX[0].timeStamp,
          accXDataHeader: item.accX && item.accX[0] && item.accX[0].data,
          empty4: "",
          accYTimeHeader: item.accY && item.accY[0] && item.accY[0].timeStamp,
          accYDataHeader: item.accY && item.accY[0] && item.accY[0].data,
          empty5: "",
          accZTimeHeader: item.accZ && item.accZ[0] && item.accZ[0].timeStamp,
          accZDataHeader: item.accZ && item.accZ[0] && item.accZ[0].data,
          empty6: "",
          gyXTimeHeader: item.gyX && item.gyX[0] && item.gyX[0].timeStamp,
          gyXDataHeader: item.gyX && item.gyX[0] && item.gyX[0].data,
          empty7: "",
          gyYTimeHeader: item.gyY && item.gyY[0] && item.gyY[0].timeStamp,
          gyYDataHeader: item.gyY && item.gyY[0] && item.gyY[0].data,
          empty8: "",
          gyZTimeHeader: item.gyZ && item.gyZ[0] && item.gyZ[0].timeStamp,
          gyZDataHeader: item.gyZ && item.gyZ[0] && item.gyZ[0].data,
        });

        if (item.emg !== undefined || item.emg !== null || item.emg !== [])
          setFileData(dataArray); // if no rawData then export patientInfo in CSV file
        for (let i = 1; i < item.emg.length; i++) {
          var timeMatchFlag = false;

          //for alerts timestamp comparison

          for (let j = 1; j < item.alerts.length; j++) {
            const alert = item.alerts[j];
            if (alert.time === item.emg[i].timeStamp) {
              dataArray.push({
                patientId: "",
                patientName: "",
                Mobile: "",
                DeviceID: "",
                alerts: 1,
                // accZTimeHeader: accZ.timeStamp,

                //  accZDataHeader: accZ.data,
              });
              timeMatchFlag = true;
            }
          }

          if (!timeMatchFlag) {
            dataArray.push({
              patientId: "",
              patientName: "",
              Mobile: "",
              DeviceID: "",
              empty1: "",
              alerts: "",

              emgTimeHeader: item.emg[i].timeStamp,
              emgDataHeader: item.emg[i].data,
              empty2: "",
              flexTimeHeader: item.flex[i].timeStamp,
              flexDataHeader: item.flex[i].data,
              empty3: "",
              accXTimeHeader: item.accX[i].timeStamp,
              accXDataHeader: item.accX[i].data,
              empty4: "",
              accYTimeHeader: item.accY[i].timeStamp,
              accYDataHeader: item.accY[i].data,
              empty5: "",
              accZTimeHeader: item.accZ[i].timeStamp,
              accZDataHeader: item.accZ[i].data,
              empty6: "",
              gyXTimeHeader: item.gyX[i].timeStamp,
              gyXDataHeader: item.gyX[i].data,
              empty7: "",
              gyYTimeHeader: item.gyY[i].timeStamp,
              gyYDataHeader: item.gyY[i].data,
              empty8: "",
              gyZTimeHeader: item.gyZ[i].timeStamp,
              gyZDataHeader: item.gyZ[i].data,
            });
          }
        }
      });
   
      setFileData(dataArray);
    }
  };

  const convertDateToSeconds = (dateTime) => {
    const cropDate = dateTime.replace(/\.\d+/, "");

    return cropDate;
  };

  const neckDataArray = [];

  const modifiedNeckCsvData = (res) => {
    if (res !== undefined || res !== null) {
      const csvFileData = [
        {
          patientName: res.patientInfo.patientName,
          patientId: res.patientInfo.patientId,
          Mobile: res.patientInfo.mobileNumber,
          deviceId: res.deviceId,

          ecg: res.rawData.ecg,
          resp: res.rawData.resp,

          spo2ir: res.rawData.spo2ir,
          spo2red: res.rawData.spo2red,

          alerts: res.patientAlerts,
        },
      ];

      csvFileData.forEach((item, index) => {
        if (index === 0) {
          neckDataArray.push({
            patientId: "",
            patientName: "",

            Mobile: "",
            DeviceID: "",
            empty1: "",
            alerts: "",

            ecgTimeHeader: "TimeStamp",
            ecgDataHeader: "Data",
            empty2: "",
            respTimeHeader: "TimeStamp",
            respDataHeader: "Data",
            empty3: "",
            spo2irTimeHeader: "TimeStamp",
            spo2irDataHeader: "Data",
            empty4: "",
            spo2redTimeHeader: "TimeStamp",
            spo2redDataHeader: "Data",
          });
        }

        neckDataArray.push({
          patientId: item.patientId,
          patientName: item.patientName,
          Mobile: item.Mobile,
          DeviceID: item.deviceId,
          empty1: "",
          alerts:
            item.alerts[0] && item.alerts[0].time === item.ecg[0].timeStamp
              ? "1"
              : "",
          ecgTimeHeader: item.ecg && item.ecg[0] && item.ecg[0].timeStamp,
          ecgDataHeader: item.ecg && item.ecg[0] && item.ecg[0].data,

          empty2: "",
          respTimeHeader: item.resp && item.resp[0] && item.resp[0].timeStamp,
          respDataHeader: item.resp && item.resp[0] && item.resp[0].data,

          empty3: "",
          spo2irTimeHeader:
            item.spo2ir && item.spo2ir[0] && item.spo2ir[0].timeStamp,
          spo2irDataHeader:
            item.spo2ir && item.spo2ir[0] && item.spo2ir[0].data,

          empty4: "",
          spo2redTimeHeader:
            item.spo2red && item.spo2red[0] && item.spo2red[0].timeStamp,
          spo2redDataHeader:
            item.spo2red && item.spo2red[0] && item.spo2red[0].data,
        });

        if (item.ecg !== undefined || item.ecg !== null || item.ecg !== [])
          setNeckFileData(neckDataArray); // if no rawData then export patientInfo in CSV file
        for (let i = 1; i < item.ecg.length; i++) {
          var timeMatchFlag = false;

          //for alerts timestamp comparison

          for (let j = 1; j < item.alerts.length; j++) {
            const alert = item.alerts[j];

            if (
              convertDateToSeconds(alert.time) ===
              convertDateToSeconds(item.ecg[i].timeStamp)
            ) {
              neckDataArray.push({
                patientId: "",
                patientName: "",
                DeviceID: "",
                Mobile: "",
                empty1: "",
                alerts: "1",
                ecgTimeHeader: item.ecg[i].timeStamp,
                ecgDataHeader: item.ecg[i].data,

                empty2: "",
                respTimeHeader: item.resp[i].timeStamp,
                respDataHeader: item.resp[i].data,

                empty3: "",
                spo2irTimeHeader: item.spo2ir[i].timeStamp,
                spo2irDataHeader: item.spo2ir[i].data,

                empty4: "",
                spo2redTimeHeader: item.spo2red[i].timeStamp,
                spo2redDataHeader: item.spo2red[i].data,
              });
              timeMatchFlag = true;
            }
          }

          if (!timeMatchFlag) {
            neckDataArray.push({
              patientId: "",
              patientName: "",
              DeviceID: "",
              Mobile: "",
              empty1: "",
              alerts: "",
              ecgTimeHeader: item.ecg[i].timeStamp,
              ecgDataHeader: item.ecg[i].data,

              empty2: "",
              respTimeHeader: item.resp[i].timeStamp,
              respDataHeader: item.resp[i].data,

              empty3: "",
              spo2irTimeHeader: item.spo2ir[i].timeStamp,
              spo2irDataHeader: item.spo2ir[i].data,

              empty4: "",
              spo2redTimeHeader: item.spo2red[i].timeStamp,
              spo2redDataHeader: item.spo2red[i].data,
            });
          }
        }
      });

     
      setNeckFileData(neckDataArray);
    }
  };

  const getPatientName = (patientName) => {
    if (patientName.length > 20) {
      return patientName.substring(0, 20) + "...";
    } else {
      return patientName;
    }
  };

  const neckbandCsvHeader0 = {
    patientId: "Patient ID",
    patientName: "Patient Name",
    Mobile: "Mobile",
    DeviceID: "DeviceID",
    empty1: "", //empty column btw two entity
    alerts: "Alerts", //empty column btw two entity
    ecgTimeHeader: "ecg",
    ecgDataHeader: "",
    empty2: "", //empty column btw two entity
    respTimeHeader: "resp",
    respDataHeader: "",
    empty3: "", //empty column btw two entity

    spo2irTimeHeader: "spo2ir",
    spo2irDataHeader: "",
    empty4: "", //empty column btw two entity
    spo2redTimeHeader: "spo2red",
    spo2redDataHeader: "",
  };

  const armbandCsvHeader0 = {
    patientId: "Patient ID",
    patientName: "Patient Name",
    Mobile: "Mobile",
    DeviceID: "DeviceID",
    empty1: "", //empty column btw two entity
    alerts: "Alerts", //empty column btw two entity
    emgTimeHeader: "emg",
    emgDataHeader: "",
    empty2: "", //empty column btw two entity
    flexTimeHeader: "flex",
    flexDataHeader: "",
    empty3: "", //empty column btw two entity

    accXTimeHeader: "accX",
    accXDataHeader: "",
    empty4: "", //empty column btw two entity
    accYTimeHeader: "accY",
    accYDataHeader: "",
    empty5: "", //empty column btw two entity
    accZTimeHeader: "accZ",
    accZDataHeader: "",
    empty6: "", //empty column btw two entity
    gyXTimeHeader: "gyX",
    gyXDataHeader: "",
    empty7: "", //empty column btw two entity
    gyYTimeHeader: "gyY",
    gyYDataHeader: "",
    empty8: "", //empty column btw two entity
    gyZTimeHeader: "gyZ",
    gyZDataHeader: "",
  };

  function convertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;

    var str = "";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ",";

        if (
          index === "ecgDataHeader" ||
          index === "respDataHeader" ||
          index === "spo2irDataHeader" ||
          index === "spo2redDataHeader"
        ) {
          line += '"' + array[i][index] + '"';
        } else {
          line += '"' + array[i][index] + '"';
        }
      }

      str += line + "\r\n";
    }

    return str;
  }

  function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
      items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);
    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + ".csv" || "export.csv";

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  return (
    <Card className="form m-2 report_container">
      {loading && <SpinnerComponent loginSpinner={"loginSpinner"} />}
      <Card.Header className="form_header ">
        <Row>
          <Col>
            <h1>Patient Report</h1>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body className="mt-3">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="input_row">
            <Col>
              <FloatingLabel
                controlId="floatingSelectGrid"
                label="Select Patient"
                data-testid="select-patient"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  {...register("selectpatient", {
                    required: "Required",
                  })}
                  onChange={selectedPatientHandler}
                >
                  <option value="" defaultChecked hidden>
                    Select...
                  </option>

                  {patientData &&
                    patientData.map((patient, index) => {
                      return (
                        <option
                          style={{
                            display: "inline-block!important",
                            width: "100px!important",
                            whiteSpace: "nowrap!important",
                            overflow: "hidden !important",
                            textOverflow: "ellipsis!important",
                            borderRight: "none!important",
                          }}
                          value={patient.patientInfo.patientId}
                          key={index}
                        >
                          {getPatientName(patient.patientInfo.patientName)}
                        </option>
                      );
                    })}
                </Form.Select>
              </FloatingLabel>
              {errors.selectpatient && (
                <h2 className="form-text text-danger invalid-text">
                  {errors.selectpatient.message}
                </h2>
              )}
            </Col>
            {/* start date  Modified date format */}
            <Col>
              <DatePicker
                maxDate={new Date(getCurrentDate())}
                name="startDate"
                inputRef={register("startDate", {
                  required: "Please select date",
                })}
                value={startDate}
                onSelect={(e) => {
                  clearErrors("startDate");
                  const date1 =
                    "" +
                    e.getFullYear() +
                    "-" +
                    singleToDoubleDigitConverter(e.getMonth() + 1) +
                    "-" +
                    singleToDoubleDigitConverter(e.getDate());

                  setStartDate(date1);
                }}
                onCalendarClose={(w) => {
                  trigger("startDate");
                }}
                onChange={(date) => setEndDate("")}
                customInput={<StartDateCustomInput />}
              />
              {errors.dob && startDate === "" && (
                <h2 className="form-text text-danger invalid-text">
                  {errors.dob.message}
                </h2>
              )}
            </Col>
            <Col>
              <DatePicker
                maxDate={new Date(getCurrentDate())}
                minDate={new Date(modifyDate(startDate))}
                name="endDate"
                {...register("endDate", {
                  required: "Please choose end date",
                })}
                value={endDate}
                onSelect={(e) => {
                  clearErrors("startDate");
                  const date1 =
                    "" +
                    e.getFullYear() +
                    "-" +
                    singleToDoubleDigitConverter(e.getMonth() + 1) +
                    "-" +
                    singleToDoubleDigitConverter(e.getDate());

                  setEndDate(date1);

                  clearErrors("endDate");
                }}
                onCalendarClose={(w) => {
                  trigger("endDate");
                }}
                customInput={<EndDateCustomInput />}
              />
            </Col>
            <Col md={2}>
              <FloatingLabel controlId="floatingSelectGrid" label="Select Band" data-testid="select-band">
                <Form.Select
                  disabled={!endDate}
                  style={{
                    border: endDate ? "" : "none",
                    backgroundColor: endDate ? "" : "rgba(43, 102, 241, 0.15)",
                  }}
                  onChange={(e) => {
                  
                    setBand(e.target.value);
                  }}
                  aria-label="Floating label select example"
                >
                  <option defaultChecked value="all">
                    All Band
                  </option>
                  <option value="armband">Arm Band</option>
                  <option value="neckband">Neck Band</option>
                </Form.Select>
              </FloatingLabel>
            </Col>

            <Col className="generate_downloadbtn">

            

              <Button
                disabled={!endDate}
                variant="success"
                role="button"
                type="button"
                data-testid="csv-download"
                //onClick={downloadHandler}
                onClick={handleDownload}
              >
                Download Report
              </Button>
            
            </Col>

            {/*   <Col className="generate_btn">
             
           <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
       Select Band
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={getArmBandCsvData} >ArmBand</Dropdown.Item>
        <Dropdown.Item onClick={getNeckBandCsvData}>NeckBand</Dropdown.Item>
        <Dropdown.Item onClick={onSubmit}>AllBand</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
 */}

            {/* 
              <Button
                variant="primary"
                role="button"
                type="submit"
                disabled={
                  !startDate || !endDate || !selectedPatient || !isValid
                }
                onClick={(e) => {
                  onSubmit(e);
                }}
              >
                {" "}
                Generate Report{" "}
              </Button>
 */}

            {/* <Button
                disabled={!btnEnable}
                variant="success"
                role="button"
                type="button"
                data-testid="csv-download"
                onClick={() => {
                  exportCSVFile(
                    neckbandCsvHeader0,
                    [...neckFileData],
                    fileTitle
                  );
                  exportCSVFile(armbandCsvHeader0, [...fileData], fileTitle1);
                }}
              >
                {" "}
                Download
              </Button> 
            </Col>*/}
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default Report;
