import changeTheNumber from "./ServiceReducer";
// import sendOTPResponse from "./ApiReducer";
import loginReducer from "./../components/login/Reducer";
import dashboarReducer from "./../components/dashboard/Reducer";
import { combineReducers } from "redux";
const rootReducer = combineReducers({
 
  changeTheNumber: changeTheNumber,
  loginReducer: loginReducer,
  dashboarReducer: dashboarReducer,
});

export default rootReducer;
