import React, { useEffect, useState } from "react";
import "./ParticipantListComponent.css";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import _ from "lodash";


export const ParticipantListComponent = (patientsData) => {
  const [patientsList, setPatientsList] = useState(patientsData.patientsData);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPatient, setSelectedPatient] = useState();
  const [paginatorObj, setPaginatorObj] = useState({
    selectedPage: "0",
    pageCount: "0",
    paginatorData: [],
  });

  useEffect(() => {
    setSelectedPatient(patientsData.patientId1);
  }, [patientsData && patientsData.patientId1]);
  useEffect(() => {
    paginatorFunction(patientsData.patientsData);
    setPatientsList(patientsData.patientsData);
  }, [patientsData && patientsData.patientsData]);

  /**
   * This function is used to set selected patient data on patient Select
   * @param {*} selectedPatient
   */
  const onTableClick = (selectedPatient) => {
    patientsData.passChildData(selectedPatient);
    setSelectedPatient(selectedPatient);
  };
  /**
   * This function is to used parse data and set to table
   * @param {*} patientsData
   */
  const paginatorFunction = (patientsData) => {
    if (
      patientsData &&
      patientsData !== undefined &&
      patientsData.length !== undefined
    ) {
      setSearchTerm("");
      setSelectedPatient("");
      const length = patientsData && patientsData.length;
      var pageCount = 0 + "";
      var selectedPage = 0;

      const lengthArray = (length / 10 + "").split(".");
      if (lengthArray.length >= 2) {
        if (+lengthArray[1] === 0) {
          pageCount = lengthArray[0];
        } else {
          pageCount = +lengthArray[0] + 1;
        }
      } else {
        pageCount = lengthArray[0];
      }
      if (pageCount > 0) {
        selectedPage = 1;
      }

      selectedPage = 1;
      let tableData = [];
      for (let i = 1 + (selectedPage - 1) * 10; i <= selectedPage * 10; i++) {
        if (length < i) {
          // return;
        } else tableData.push(patientsData[i - 1]);
      }
      setPaginatorObj({
        pageSize: pageCount,
        selectedPage: selectedPage,
        tableData: tableData,
      });
    }
  };

  /**
   * This function is used to set paginator object and table data depending on selected page
   * @param {*} page
   */
  const tableHandle = (page) => {
    const length = patientsList.length;

    if (length > 0) {
      const selectedPage = page;
      let tableData = [];
      for (let i = 1 + (selectedPage - 1) * 10; i <= selectedPage * 10; i++) {
        if (length < i) {
          // return;
        } else tableData.push(patientsList[i - 1]);
      }

      setPaginatorObj({
        ...paginatorObj,
        selectedPage: page,
        tableData: tableData,
      });
    }
  };
  /**
   * This function is to return array to search element
   * @param {*} searchTerm
   */
  const searchFunction = (searchTerm) => {
    const searchArray = [];
    if (searchTerm !== "" && searchTerm !== undefined) {
      if (patientsList) {
        patientsList.forEach((element) => {
          if (
            element.patientInfo.patientName
              .toLowerCase()
              .includes(searchTerm.toLocaleLowerCase())
          ) {
            searchArray.push(element);

            paginatorFunction(searchArray);
          }
        });
      }
    } else {
      //string is empty
      paginatorFunction(patientsList);
    }
  };

  /**
   * This function is used to get Name
   * @param {*} patientName
   * @returns
   */
  const getPatientName = (patientName) => {
    if (patientName.length > 14) {
      return patientName.substring(0, 14) + "...";
    } else {
      return patientName;
    }
  };

  /**
   * This function is used to modify date
   * @param {*} inputDate
   * @returns
   */
  const modifyDate = (inputDate) => {
    if (inputDate !== "" && inputDate !== undefined) {
      const newDate = new Date(inputDate);
      let date = "";
      if (newDate.getDate() < 10) {
        date = "0" + newDate.getDate().toString();
      } else {
        date = newDate.getDate();
      }
      const month = newDate.getMonth();
      const year = newDate.getFullYear().toString().substring(2);
      const convertDate = new Date(year, month, date); // 2009-11-10
      const month1 = convertDate.toLocaleString("default", { month: "short" });

      return month1 + " " + date + "," + " '" + year;
    } else return "-";
  };
  return (
    <Card className="table_top">
      <Card.Header className="table_header">
        <Col>
          <h1> PARTICIPANT LIST</h1>
        </Col>
        <Col>
          <div className="search_box">
            <Form.Control
              className="search_input"
              data-testid="search-input"
              type="search"
              //value={searchTerm}

              onChange={(e) => {
                setSearchTerm(e.target.value);
                searchFunction(e.target.value);
              }}
            />

            <Button
              className="search_btn"
              data-testid="search-btn"
              type="button"
            >
              <img
                src="images/searchicon.png"
                className="search_icon"
                alt="searchIcon"
              ></img>
            </Button>
          </div>
        </Col>
      </Card.Header>
      <div className="table_subheading">
        <h1>All</h1>
      </div>
      <div className="table_body">
        <div className="table-container">
          <Table
            className="main_table"
            hover
            size="sm"
            data-testid="ParticipantList-table"
            id="table-id"
          >
            <thead>
              <tr>
                <th>Patient ID</th>
                <th className="table-name">Name</th>
                <th>Age</th>
                <th>M/F</th>
                <th>Enrolled</th>
              </tr>
            </thead>
            <tbody>
              {paginatorObj.tableData &&
                paginatorObj.tableData
                  .filter((val) => {
                    if (searchTerm === "") {
                      return val.patientInfo;
                    } else if (
                      val.patientInfo.patientName
                        .toLowerCase()
                        .includes(searchTerm.toLocaleLowerCase())
                    ) {
                      return val.patientInfo;
                    }
                  })
                  //
                  .map((m, index) => (
                    <tr
                      key={index}
                      onClick={(e) => onTableClick(m.patientInfo.patientId)}
                      style={{
                        borderLeft:
                          selectedPatient === m.patientInfo.patientId
                            ? "5px solid rgb(21 73 230)"
                            : "5px solid transparent",
                        cursor: "pointer",
                      }}
                    >
                      <td>{m.patientInfo.patientId}</td>
                      <td className="table-name">
                        {getPatientName(m.patientInfo.patientName)}
                      </td>
                      <td>{m.patientInfo.age}</td>
                      <td>{m.patientInfo.gender}</td>
                      <td>
                        {modifyDate(
                          m.patientInfo.enrolledDate &&
                            m.patientInfo.enrolledDate
                        )}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        </div>

        <nav
          className="pagination_container"
          aria-label="Page navigation example"
          data-testid="pagination"
        >
          <ul className="pagination">
            <span className="pagination_text">
              Patient List {paginatorObj.selectedPage} of{" "}
              {paginatorObj.pageSize}
            </span>
            <li className="page-item">
              <button
                className={
                  paginatorObj.selectedPage <= 2
                    ? "page-link"
                    : "page-link active_btn"
                }
                href="#"
                aria-label="Previous"
                disabled={paginatorObj.selectedPage <= 2}
                onClick={(e) => {
                  e.preventDefault();

                  tableHandle(paginatorObj.selectedPage - 2);
                }}
              >
                <span>&laquo;</span>
              </button>
            </li>
            <li className="page-item">
              <button
                className={
                  paginatorObj.selectedPage <= 1
                    ? "page-link"
                    : "page-link active_btn"
                }
                href="#"
                disabled={paginatorObj.selectedPage <= 1}
                onClick={(e) => {
                  e.preventDefault();

                  tableHandle(paginatorObj.selectedPage - 1);
                }}
              >
                {"<"}
              </button>
            </li>

            <li className="page-item">
              <button
                disabled={paginatorObj.selectedPage >= paginatorObj.pageSize}
                className={
                  paginatorObj.selectedPage >= paginatorObj.pageSize
                    ? "page-link"
                    : "page-link active_btn"
                }
                href="#"
                onClick={(e) => {
                  e.preventDefault();

                  tableHandle(paginatorObj.selectedPage + 1);
                }}
              >
                {">"}
              </button>
            </li>
            <li className="page-item">
              <button
                className={
                  paginatorObj.selectedPage + 1 >= paginatorObj.pageSize
                    ? "page-link"
                    : "page-link active_btn"
                }
                href="#"
                aria-label="Next"
                disabled={
                  paginatorObj.selectedPage + 1 >= paginatorObj.pageSize
                }
                onClick={(e) => {
                  e.preventDefault();
                  tableHandle(paginatorObj.selectedPage + 2);
                }}
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </Card>
  );
};
