// import axios from "axios";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

const url = process.env.REACT_APP_SERVER_BASE_URL;

const tokenStr =
  "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImV4cCI6MTY1MDM4OTU3OSwiaWF0IjoxNjUwMzcxNTc5fQ.6FKkiiV1gnEVSWJNxuURc-y-RvH0Yy6BP3KjqSsRr2Xy4f18eWB04VwXTFRKOBw-JeguVqGLWmeRGpvZyCtsKg";

export function* handlePostSetOTP(action) {
  try {
    console.log("postSendOTP", action.payload);
    const sendOTPResponse = yield fetch(url + "sms/sendOTP", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${tokenStr}`
      },
      body: JSON.stringify(action.payload),
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });

    console.log("sendOTPResponse", sendOTPResponse);
    yield put({
      type: "SEND_OTP_SUCCESS",
      sendOTPResponse: sendOTPResponse,
      sendOTPflag: true,
    });
  } catch (err) {
    yield put({
      type: "SEND_OTP_FAILED",
      message: err.message,
      sendOTPflag: false,
    });
  }
}

export function* watcherAPISaga1() {
  yield takeLatest("SEND_OTP_REQUESTED", handlePostSetOTP);
}

export function* validateOTP(action) {
  put({ sendOTPflag: false });
  try {
    console.log("validateOTP", action.payload);
    const validateOTPResponse = yield fetch(url + "sms/validateOTP", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${tokenStr}`
      },
      body: JSON.stringify(action.payload),
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });

    console.log("sendOTPResponse", validateOTPResponse);
    if (validateOTPResponse.status != "FAIL") {
      console.log("validate otp success", validateOTPResponse);
      yield put({
        type: "VALIDATE_OTP_SUCCESS",
        validateOTPResponse: validateOTPResponse,
      });
    } else
      yield put({
        type: "VALIDATE_OTP_FAILED",
        message: validateOTPResponse.message,
      });

    // yield put({
    //   type: "VALIDATE_OTP_SUCCESS",
    //   validateOTPResponse: validateOTPResponse,
    // });
  } catch (err) {
    yield put({ type: "VALIDATE_OTP_FAILED", message: err.message });
  }
}

export function* watcherValidateOTP() {
  yield takeLatest("VALIDATE_OTP_REQUESTED", validateOTP);
}
