export const saveJWT = (token) => {
  return {
    type: "SAVEJWT",
    payload: token,
  };
};
export const sendOTP = (payload) => {
  return {
    type: "SEND_OTP_REQUESTED",
    payload: payload,
  };
};

export const validateOTP = (payload) => {
  return {
    type: "VALIDATE_OTP_REQUESTED",
    payload: payload,
  };
};

export const logout = (payload) => {
  return {
    type: "LOGOUT",
    payload: payload,
  };
};

export const setToastr = (payload) => {
  return {
    type: "SET_TOASTR",
    payload: payload,
  };
};
