import React from "react";
import { Routes, Route } from "react-router-dom";
import LeftMenu from "../leftMenu/LeftMenu";
import Toolbar from "../toolbar/Toolbar";
import Report from "../report/Report";
import "./DashboardContainer.css";
import { Dashboard } from "../dashboard/Dashboard";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { saveJWT } from "./../login/Index";
import { useDispatch } from "react-redux";



const DashboardContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [menuSelected, setMenuSelected] = useState(
    location.pathname.replace("/", "")
  );
  const [showComponent, setShowComponent] = useState(false);
  const childToParent = (menu) => {
   
    setMenuSelected(menu);
  };
  useEffect(() => {
    navigate(menuSelected);
  }, [menuSelected]);

  let i = 0;
  useEffect(() => {
   

    if (i == 0) {
      // eslint-disable-next-line no-lone-blocks
      {
        let jwtTokenTemp = "";
        if (
          sessionStorage.getItem("jwtToken") != undefined &&
          sessionStorage.getItem("jwtToken") != null &&
          sessionStorage.getItem("jwtToken") != ""
        ) {
          jwtTokenTemp = JSON.parse(sessionStorage.getItem("jwtToken") + "");
          setShowComponent(true);
        
        } else {
         
          navigate("/login");

          dispatch(saveJWT(jwtTokenTemp));
        }
        dispatch(saveJWT(jwtTokenTemp));
      }
    }
    i = i + 1;
  }, []);

  return (
    <>
      
      {showComponent && (
        <div className="dashboard-container" data-testid="dashboard-container">
          <Toolbar></Toolbar>
          <div className="sub-container">
            <LeftMenu
              menuSelected={menuSelected}
              childToParent={childToParent}
              className="left-menu"
            />
            <Routes className="other-container">
              <Route path="dashboard" element={<Dashboard />}></Route>
              <Route path="report" element={<Report />}></Route>
           
            </Routes>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardContainer;
