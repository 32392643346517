import { React, useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./EpilepsyHistoryComponent.css";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setToastr } from "../../../login/Index";

export const EpilepsyHistoryComponent = (props) => {
  const dispatch = useDispatch();
  const [lastTaken, setLastTaken] = useState(null);
  const {
    register,
    formState: { errors },
    clearErrors,
    trigger,
  } = useForm();

  const [formVal, setFormVal] = useState([]);
  const [visitSelected, setVisitSelected] = useState(0);
/**
 * Created custom date picker
 * @param {*} param0 
 * @returns 
 */
  const CustomDatePicker = ({ value, onClick, index }) => {
    return (
      <FloatingLabel controlId="floatingSelectGrid" label="Last Taken">
        <Form.Control
          data-testid="last-taken"
          autoComplete="off"
          defaultValue={modifyDate(formVal && formVal[index].lastTaken)}
          type="text"
          placeholder="Last Taken"
          onClick={onClick}
          disabled={index < formVal.length - 1}
          style={{
            border: index < formVal.length - 1 && "none",
            backgroundColor:
              index < formVal.length - 1 && "rgba(43, 102, 241, 0.15)",
          }}
        />
      </FloatingLabel>
    );
  };
  const jwtToken = useSelector(
    (state) => state.rootReducer1.loginReducer.jwtToken
  );
  const url = useSelector(
    (state) => state.rootReducer1.changeTheNumber.serverBaseUrl
  );
/**
 * This function is used to check form is valid or not.
 */
  useEffect(() => {
    if (props.invalidForm === "invalid") {
      formVal.forEach((element, index) => {
        if (element.medicationName !== "")
          for (const [key, value] of Object.entries(element)) {
            if (value === "") trigger(getName(key, index));
            else {
              clearErrors(getName(key, index));
            }
          }
      });
    } else {
      clearErrors();
    }
  }, [props && props.invalidForm]);


/**
 * This function is used to set the form value, generage the new prescription id.
 */
  useEffect(() => {
    if (props.test && props.data) {
      formValueSetter();
    }
    if (props && props.data != null) {
      clearErrors();
      setVisitSelected(0);
      const index = props.data.length - 1;
      if (props.data[index] && props.data[index].presciptionId == "") {
        fetch(url + "createPatientId", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status == "SUCCESS") {
              props.data[index].presciptionId = json.data.patientId;

              formValueSetter();
            } else {
              formValueSetter();
            }
          });
      }
    }
  }, [props && props.data, lastTaken]);
  /**
   * This function is used to set the values of form on initial stage and also used to parse value to required form.
   */
  const formValueSetter = () => {
    if (props && props.data) {
      setFormVal(props.data);
      formVal.forEach((val) => {
        const date = new Date(val.lastTaken);
        const formattedDate = date
          .toLocaleDateString("default", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })
          .replace(/ /g, "");

        setLastTaken(formattedDate);
      });
    }
  };

  /**
   * This function is use to set the values of form on change
   * @param {*} e
   */
  const handleChange = (e) => {
    e.preventDefault();
    formVal.forEach((element, index) => {
      if (index == visitSelected) {
        element[e.target.name] = e.target.value;
      }
    });
  };
  /**
   * This function is use to return the values of form on change
   */
  useEffect(() => {
    props.historyToDashboard(formVal);
  }, [formVal]);
  /**
   * This function is used to return the key or name as we are using form array here so we need to add index to key for uniqueness.
   * @param {*} nam
   * @param {*} index
   * @returns
   */
  const getName = (nam, index) => {
    return nam + index;
  };
  /**
   * This function is used to validate key values of form.
   * @param {*} e
   */
  const validationCheck = (e) => {
    const value = e.target.value;

    const key = e.target.name;

    if (value != "") {
      clearErrors(key);
    } else {
      trigger(key);
    }

    if (key === getName("count", visitSelected)) {
      if (value > 10) {
        trigger(key);
      } else {
        clearErrors(key);
      }
    }
  };

  /**
   * This function is used to parse date to required format
   * @param {*} dateInput
   * @returns
   */
  const modifyDate = (dateInput) => {
    if (dateInput !== "" && dateInput !== undefined) {
      const newDate = new Date(dateInput);
      let date = "";
      if (newDate.getDate() < 10) {
        date = "0" + newDate.getDate().toString();
      } else {
        date = newDate.getDate();
      }

      const month = newDate.getMonth();
      const year = newDate.getFullYear().toString();
      const convertDate = new Date(year, month, date); // 2009-11-10
      const month1 = convertDate.toLocaleString("default", { month: "short" });

      return month1 + " " + date + "," + " " + year;
    } else return "";
  };
  return (
    <Card className="form" id="ep_form">
      <Card.Header className="form_header">
        <Row>
          <Col>
            <h1> EPILEPSY HISTORY</h1>
          </Col>
        </Row>
      </Card.Header>
      <Form>
        <div className="form_tabs " data-testid="page-tabs">
          <ul className="form_tabs_ul">
            {" "}
            {formVal &&
              formVal.map((e, index) => {
                return (
                  <div key={index}>
                    {index + 1 < formVal.length ? (
                      <li
                        style={{ cursor: "pointer" }}
                        className={`${
                          visitSelected == index ? "selectedTab" : ""
                        }`}
                        key={index}
                        onClick={(e) => {
                          setVisitSelected(index);
                        }}
                      >
                        <a> Visit {index + 1} </a>
                      </li>
                    ) : (
                      <>
                        {formVal.length === 1 ? (
                          <>
                            {" "}
                            <li
                              style={{ cursor: "pointer" }}
                              className={`${
                                visitSelected == index ? "selectedTab" : ""
                              }`}
                              key={"a" + index}
                              onClick={(e) => {
                                setVisitSelected(index);
                              }}
                            >
                              <a> Visit {index + 1} </a>
                            </li>
                            <li
                              style={{
                                cursor: "pointer",
                                color:
                                  formVal.length === 1
                                    ? "rgb(146, 146, 151)"
                                    : "black",
                              }}
                              disabled
                              key={index}
                            >
                              <a> + </a>
                            </li>
                          </>
                        ) : (
                          <li
                            style={{
                              cursor: "pointer",

                              color:
                                formVal.length === 1
                                  ? "rgb(146, 146, 151)"
                                  : "black",
                            }}
                            className={`${
                              visitSelected == index ? "selectedTab" : ""
                            }`}
                            key={index}
                            onClick={(e) => {
                              setVisitSelected(index);
                            }}
                          >
                            <a> + </a>
                          </li>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
          </ul>
        </div>

        {formVal &&
          formVal.map((e, index) => {
            if (index === visitSelected) {
              return (
                <div key={e.presciptionId + "" + index}>
                  <Card.Body>
                    <div className="form_subheading">
                      <img src="./images/pill_icon.png" alt="" />
                      <h2> Medication & Dose prior to EMU visit</h2>
                    </div>

                    <Row className="g-3">
                      <Col md={3} style={{ maxWidth: "200px" }}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Prescription ID"
                        >
                          <Form.Control
                            style={{
                              border: "none",
                              backgroundColor: "rgba(43, 102, 241, 0.15)",
                            }}
                            type="text"
                            data-testid="uniqueId-input"
                            placeholder="Prescription ID"
                            name="presciptionId"
                            defaultValue={e.presciptionId}
                            onChange={handleChange}
                            disabled
                          />
                        </FloatingLabel>
                        {errors.presciptionId && (
                          <h2 className="form-text text-danger invalid-text">
                            {errors.presciptionId.message}
                          </h2>
                        )}
                      </Col>

                      <Col md={5}>
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="Medication"
                        >
                          <Form.Select
                            data-testid="medication"
                            value={e.medicationName}
                            aria-label="Floating label select example"
                            name={getName("medicationName", index)}
                            style={{
                              border: index < formVal.length - 1 && "none",
                              backgroundColor:
                                index < formVal.length - 1 &&
                                "rgba(43, 102, 241, 0.15)",
                            }}
                            onChange={(e) => {
                              const medicationName = e.target.value;
                              setFormVal((currentForm) =>
                                currentForm.map((x, index1) =>
                                  index1 == visitSelected
                                    ? { ...x, medicationName }
                                    : x
                                )
                              );
                              validationCheck(e);
                            }}
                            onClick={validationCheck}
                            disabled={index < formVal.length - 1}
                            inputRef={register(
                              "" + getName("medicationName", index),
                              {
                                required: "Please select medicine",
                              }
                            )}
                          >
                            <option defaultChecked value="" hidden>
                              Select...
                            </option>
                            <option value="Diazapam, 10mg, IV">
                              Diazapam, 10mg, IV
                            </option>
                            <option value="Diazapam, 10mg,V">
                              Diazapam, 10mg, V
                            </option>
                          </Form.Select>
                        </FloatingLabel>
                        {errors[getName("medicationName", index)] && (
                          <h2 className="form-text text-danger invalid-text">
                            {errors[getName("medicationName", index)].message}
                          </h2>
                        )}
                      </Col>

                      <Col md={2}>
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="Dose (mg)"
                        >
                          <Form.Control
                            data-testid="dose"
                            name={getName("dose", index)}
                            value={e.dose}
                            type="number"
                            placeholder="Dose"
                            disabled={index < formVal.length - 1}
                            style={{
                              border: index < formVal.length - 1 && "none",
                              backgroundColor:
                                index < formVal.length - 1 &&
                                "rgba(43, 102, 241, 0.15)",
                            }}
                            inputRef={register("" + getName("dose", index), {
                              required: "Required",
                            })}
                            onClick={validationCheck}
                            onChange={(e) => {
                              if (index < formVal.length - 1) {
                              } else if (e.target.value.length >= 4) {
                                dispatch(
                                  setToastr([
                                    {
                                      show: true,
                                      delay: 3000,
                                      onClose: false,
                                      header: "Warning",
                                      message: "Maximum 3 digits allowed.",
                                      color: "warning",
                                    },
                                  ])
                                );
                              }
                              const dose = e.target.value.slice(0, 3);
                              validationCheck(e);
                              setFormVal((currentForm) =>
                                currentForm.map((x, index1) =>
                                  index1 == visitSelected ? { ...x, dose } : x
                                )
                              );
                            }}
                          />
                        </FloatingLabel>

                        {errors[getName("dose", index)] && (
                          <h2 className="form-text text-danger invalid-text">
                            {errors[getName("dose", index)].message}
                          </h2>
                        )}
                      </Col>

                      <Col md={2}>
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="Form"
                        >
                          <Form.Select
                            data-testid="form"
                            value={e.form}
                            aria-label="Floating label select example"
                            name={getName("form", index)}
                            style={{
                              border: index < formVal.length - 1 && "none",
                              backgroundColor:
                                index < formVal.length - 1 &&
                                "rgba(43, 102, 241, 0.15)",
                            }}
                            onChange={(e) => {
                              const form = e.target.value;
                              setFormVal((currentForm) =>
                                currentForm.map((x, index1) =>
                                  index1 == visitSelected ? { ...x, form } : x
                                )
                              );
                              validationCheck(e);
                            }}
                            onClick={validationCheck}
                            disabled={index < formVal.length - 1}
                            inputRef={register("" + getName("form", index), {
                              required: "Please select",
                            })}
                          >
                            <option defaultChecked value="" hidden>
                              Select...
                            </option>
                            <option value="IV">IV</option>
                            <option value="pill">pill</option>
                            <option value="s.c">s.c</option>
                          </Form.Select>
                        </FloatingLabel>
                        {errors[getName("form", index)] && (
                          <h2 className="form-text text-danger invalid-text">
                            {errors[getName("form", index)].message}
                          </h2>
                        )}
                      </Col>
                      <Col md={2}>
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="Count"
                        >
                          <Form.Control
                            data-testid="count"
                            name={getName("count", index)}
                            type="number"
                            max="10"
                            min="1"
                            value={e.count}
                            placeholder="count"
                            disabled={index < formVal.length - 1}
                            style={{
                              border: index < formVal.length - 1 && "none",
                              backgroundColor:
                                index < formVal.length - 1 &&
                                "rgba(43, 102, 241, 0.15)",
                            }}
                            inputRef={register("" + getName("count", index), {
                              required: "Required",
                            })}
                            onClick={validationCheck}
                            onChange={(e) => {
                              if (e.target.value.length > 2) {
                                validationCheck(e);

                                dispatch(
                                  setToastr([
                                    {
                                      show: true,
                                      delay: 3000,
                                      onClose: false,
                                      header: "Warning",
                                      message: "Maximum 2 digits allowed.",
                                      color: "warning",
                                    },
                                  ])
                                );
                              }
                              if (e.target.value > 10) {
                                validationCheck(e);

                                dispatch(
                                  setToastr([
                                    {
                                      show: true,
                                      delay: 3000,
                                      onClose: false,
                                      header: "Warning",
                                      message: "Choose between 1-10.",
                                      color: "warning",
                                    },
                                  ])
                                );
                              }

                              const count = e.target.value.slice(0, 2);
                              validationCheck(e);
                              setFormVal((currentForm) =>
                                currentForm.map((x, index1) =>
                                  index1 == visitSelected ? { ...x, count } : x
                                )
                              );
                            }}
                          />
                        </FloatingLabel>

                        {errors[getName("count", index)] && (
                          <h2 className="form-text text-danger invalid-text">
                            {errors[getName("count", index)].message}
                          </h2>
                        )}
                      </Col>

                      <Col md={2}>
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="Frequency"
                        >
                          <Form.Select
                            data-testid="frequency"
                            value={e.frequency}
                            aria-label="Floating label select example"
                            name={getName("frequency", index)}
                            style={{
                              border: index < formVal.length - 1 && "none",
                              backgroundColor:
                                index < formVal.length - 1 &&
                                "rgba(43, 102, 241, 0.15)",
                            }}
                            onChange={(e) => {
                              const frequency = e.target.value;
                              setFormVal((currentForm) =>
                                currentForm.map((x, index1) =>
                                  index1 == visitSelected
                                    ? { ...x, frequency }
                                    : x
                                )
                              );
                              validationCheck(e);
                            }}
                            onClick={validationCheck}
                            disabled={index < formVal.length - 1}
                            inputRef={register(
                              "" + getName("frequency", index),
                              {
                                required: "Please select",
                              }
                            )}
                          >
                            <option defaultChecked value="" hidden>
                              Select...
                            </option>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                          </Form.Select>
                        </FloatingLabel>
                        {errors[getName("frequency", index)] && (
                          <h2 className="form-text text-danger invalid-text">
                            {errors[getName("frequency", index)].message}
                          </h2>
                        )}
                      </Col>

                      <Col md={4}>
                        <DatePicker
                          maxDate={new Date()}
                          name={getName("lastTaken", index)}
                          defaultValue={e.lastTaken}
                          inputRef={register("" + getName("lastTaken", index), {
                            required: "Please select date",
                          })}
                          autoComplete="off"
                          onSelect={(e) => {
                            e.setDate(e.getDate() + 1);
                            const lastTaken = new Date(e)
                              .toISOString()
                              .split("T")[0];
                            clearErrors(getName("lastTaken", index));
                            setFormVal((currentForm) =>
                              currentForm.map((x, index1) =>
                                index1 == visitSelected
                                  ? { ...x, lastTaken }
                                  : x
                              )
                            );
                          }}
                          onCalendarClose={(e) => {
                            trigger(getName("lastTaken", index));
                          }}
                          customInput={<CustomDatePicker index={index} />}
                        />
                        {errors[getName("lastTaken", index)] &&
                          e.lastTaken === "" && (
                            <h2 className="form-text text-danger invalid-text">
                              {errors[getName("lastTaken", index)].message}
                            </h2>
                          )}
                      </Col>
                    </Row>
                  </Card.Body>
                  <hr />
                  <Card.Body>
                    <div className="form_subheading ">
                      <img src="./images/pill_icon.png" alt="" />
                      <h2> Epilepsy Metrics</h2>
                    </div>
                    <Row className="g-3">
                      <Col md={4}>
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="Years with Epilepsy"
                        >
                          <Form.Select
                            data-testid="yearsWEpilepsy"
                            name={getName("yearsWEpilepsy", index)}
                            value={e.yearsWEpilepsy}
                            style={{
                              border: index < formVal.length - 1 && "none",
                              backgroundColor:
                                index < formVal.length - 1 &&
                                "rgba(43, 102, 241, 0.15)",
                            }}
                            aria-label="Floating label select example"
                            inputRef={register(
                              "" + getName("yearsWEpilepsy", index),
                              {
                                required: "Please select year",
                              }
                            )}
                            disabled={index < formVal.length - 1}
                            onClick={validationCheck}
                            onChange={(e) => {
                              const yearsWEpilepsy = e.target.value;

                              validationCheck(e);
                              setFormVal((currentForm) =>
                                currentForm.map((x, index1) =>
                                  index1 === visitSelected
                                    ? { ...x, yearsWEpilepsy }
                                    : x
                                )
                              );
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select...
                            </option>
                            <option key={0} value={0}>
                              {"< 1"}
                            </option>
                            {[...Array(100)].map((x, i) => {
                              return (
                                <option key={i} value={i + 1}>
                                  {i + 1}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                        {errors[getName("yearsWEpilepsy", index)] && (
                          <h2 className="form-text text-danger invalid-text">
                            {errors[getName("yearsWEpilepsy", index)].message}
                          </h2>
                        )}
                      </Col>
                      <Col md={4}>
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="Year of Diagnosis"
                        >
                          <Form.Select
                            data-testid="yearOfDiagnosis"
                            name={getName("yearOfDiagnosis", index)}
                            value={e.yearOfDiagnosis}
                            disabled={index < formVal.length - 1}
                            style={{
                              border: index < formVal.length - 1 && "none",
                              backgroundColor:
                                index < formVal.length - 1 &&
                                "rgba(43, 102, 241, 0.15)",
                            }}
                            aria-label="Floating label select example"
                            inputRef={register(
                              "" + getName("yearOfDiagnosis", index),
                              {
                                required: "Please select year",
                              }
                            )}
                            onClick={validationCheck}
                            onChange={(e) => {
                              const yearOfDiagnosis = e.target.value;

                              setFormVal((currentForm) =>
                                currentForm.map((x, index1) =>
                                  index1 == visitSelected
                                    ? { ...x, yearOfDiagnosis }
                                    : x
                                )
                              );
                            }}
                            size="5"
                          >
                            <option defaultChecked value="" hidden>
                              Select...
                            </option>
                            {[...Array(30)].map((x, i) => {
                              return (
                                <option key={i} value={2022 - i}>
                                  {2022 - i}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                        {errors[getName("yearOfDiagnosis", index)] && (
                          <h2 className="form-text text-danger invalid-text">
                            {errors[getName("yearOfDiagnosis", index)].message}
                          </h2>
                        )}
                      </Col>
                      <Col md={4}>
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="Laterality"
                        >
                          <Form.Select
                            data-testid="laterality"
                            name={getName("laterality", index)}
                            value={e.laterality}
                            aria-label="Floating label select example"
                            style={{
                              border: index < formVal.length - 1 && "none",
                              backgroundColor:
                                index < formVal.length - 1 &&
                                "rgba(43, 102, 241, 0.15)",
                            }}
                            disabled={index < formVal.length - 1}
                            inputRef={register(
                              "" + getName("laterality", index),
                              {
                                required: "Please select laterality",
                              }
                            )}
                            onClick={validationCheck}
                            onChange={(e) => {
                              const laterality = e.target.value;
                              validationCheck(e);
                              setFormVal((currentForm) =>
                                currentForm.map((x, index1) =>
                                  index1 == visitSelected
                                    ? { ...x, laterality }
                                    : x
                                )
                              );
                            }}
                          >
                            <option value="" hidden>
                              Select...
                            </option>
                            <option value="N/A">N/A</option>
                            <option value="Right">Right</option>
                            <option value="Left">Left</option>
                          </Form.Select>
                        </FloatingLabel>

                        {errors[getName("laterality", index)] && (
                          <h2 className="form-text text-danger invalid-text">
                            {errors[getName("laterality", index)].message}
                          </h2>
                        )}
                      </Col>
                    </Row>
                    <Row className="g-3" style={{ marginTop: "0px" }}>
                      <Col md={4}>
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="Avg Number of Seizures/month"
                        >
                          <Form.Select
                            data-testid="avgSeizuresPerMonth"
                            name={getName("avgSeizuresPerMonth", index)}
                            value={e.avgSeizuresPerMonth}
                            aria-label="Floating label select example"
                            style={{
                              border: index < formVal.length - 1 && "none",
                              backgroundColor:
                                index < formVal.length - 1 &&
                                "rgba(43, 102, 241, 0.15)",
                            }}
                            placeholder="select avg seizures/month"
                            disabled={index < formVal.length - 1}
                            inputRef={register(
                              "" + getName("avgSeizuresPerMonth", index),
                              {
                                required: "Please select count",
                              }
                            )}
                            onClick={validationCheck}
                            onChange={(e) => {
                              const avgSeizuresPerMonth = e.target.value;
                              validationCheck(e);
                              setFormVal((currentForm) =>
                                currentForm.map((x, index1) =>
                                  index1 == visitSelected
                                    ? { ...x, avgSeizuresPerMonth }
                                    : x
                                )
                              );
                            }}
                          >
                            <option defaultChecked value="" hidden>
                              Select...
                            </option>
                            {[...Array(100)].map((x, i) => {
                              return (
                                <option key={i} value={i + 1}>
                                  {i + 1}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                        {errors[getName("avgSeizuresPerMonth", index)] && (
                          <h2 className="form-text text-danger invalid-text">
                            {
                              errors[getName("avgSeizuresPerMonth", index)]
                                .message
                            }
                          </h2>
                        )}
                      </Col>
                      <Col md={4}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Avg. Sleep Cycle (App Input)"
                        >
                          <Form.Control
                            disabled
                            style={{
                              border: "none",
                              backgroundColor: "rgba(43, 102, 241, 0.15)",
                            }}
                            name="avgSleepCycle"
                            value={e.avgSleepCycle}
                            type="text"
                            placeholder="Avg. Sleep Cycle (App Input)"
                            data-testid="sleepCycle-input"
                            onChange={(e) => {
                              const avgSleepCycle = e.target.value;

                              setFormVal((currentForm) =>
                                currentForm.map((x, index1) =>
                                  index1 == visitSelected
                                    ? { ...x, avgSleepCycle }
                                    : x
                                )
                              );
                            }}
                          />
                        </FloatingLabel>{" "}
                        {errors.avgSleepCycle && (
                          <h2 className="form-text text-danger invalid-text">
                            {errors.avgSleepCycle.message}
                          </h2>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                  <hr />
                  <Card.Body style={{ paddingBottom: "0px" }}>
                    <div className="form_subheading ">
                      <img src="./images/pill_icon.png" alt="" />
                      <h2> Epilepsy Notes</h2>
                    </div>
                    <div>
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Type of seizures"
                        className={
                          index < formVal.length - 1
                            ? "mb-3 input_textarea_disable "
                            : "mb-3 input_textarea "
                        }
                      >
                        <Form.Control
                          disabled={index < formVal.length - 1}
                          maxLength="250"
                          style={{
                            border: index < formVal.length - 1 && "none",
                            backgroundColor:
                              index < formVal.length - 1 &&
                              "rgba(43, 102, 241, 0.15)",
                            height: "48px",
                          }}
                          onKeyUp={(e) => {
                            e.preventDefault();

                            if (e.target.value.length >= 250) {
                              dispatch(
                                setToastr([
                                  {
                                    show: true,
                                    delay: 3000,
                                    onClose: false,
                                    header: "Warning",
                                    message: "Maximum character count reached.",
                                    color: "warning",
                                  },
                                ])
                              );
                            }
                          }}
                          name={getName("typesOfSeizures", index)}
                          inputRef={register(
                            "" + getName("typesOfSeizures", index)
                          )}
                          defaultValue={e.typesOfSeizures}
                          as="textarea"
                          placeholder="Leave a comment here"
                          data-testid="seizures-input"
                          onChange={(e) => {
                            const typesOfSeizures = e.target.value;

                            setFormVal((currentForm) =>
                              currentForm.map((x, index1) =>
                                index1 == visitSelected
                                  ? { ...x, typesOfSeizures }
                                  : x
                              )
                            );
                          }}
                          onClick={(e) => {
                            validationCheck(e);
                          }}
                        />
                        {errors[getName("typesOfSeizures", index)] && (
                          <h2 className="form-text text-danger invalid-text">
                            {errors[getName("typesOfSeizures", index)].message}
                          </h2>
                        )}
                      </FloatingLabel>
                    </div>

                    <div>
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Allergies "
                        className={
                          index < formVal.length - 1
                            ? "mb-3 input_textarea_disable "
                            : "mb-3 input_textarea "
                        }
                      >
                        <Form.Control
                          name={getName("allergies", index)}
                          maxLength="250"
                          style={{
                            border: index < formVal.length - 1 && "none",
                            backgroundColor:
                              index < formVal.length - 1 &&
                              "rgba(43, 102, 241, 0.15)",
                            height: "48px",
                          }}
                          onKeyUp={(e) => {
                            e.preventDefault();
                            if (e.target.value.length >= 250) {
                              dispatch(
                                setToastr([
                                  {
                                    show: true,
                                    delay: 3000,
                                    onClose: false,
                                    header: "Warning",
                                    message: "Maximum character count reached.",
                                    color: "warning",
                                  },
                                ])
                              );
                            }
                          }}
                          disabled={index < formVal.length - 1}
                          defaultValue={e.allergies}
                          as="textarea"
                          placeholder="Leave a comment here"
                          data-testid="allergy-input"
                          onChange={(e) => {
                            const allergies = e.target.value;

                            setFormVal((currentForm) =>
                              currentForm.map((x, index1) =>
                                index1 == visitSelected
                                  ? { ...x, allergies }
                                  : x
                              )
                            );
                          }}
                        />
                      </FloatingLabel>
                    </div>
                  </Card.Body>
                </div>
              );
            }
          })}
      </Form>
    </Card>
  );
};
