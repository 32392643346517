/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import { EpilepsyHistoryComponent } from "../subcomponents/forms/epilepsy-history/EpilepsyHistoryComponent";
import { OtherMedicalInformationComponent } from "../subcomponents/forms/other-medical-information/OtherMedicalInformationComponent";
import { ParticipantInfromationComponent } from "../subcomponents/forms/participant-information-component/ParticipantInfromationComponent";
import { ParticipantDetailsComponent } from "../table/participant-details/ParticipantDetailsComponent";
import { ParticipantListComponent } from "../table/participant-list-component/ParticipantListComponent";
import { getPatientID, getPatients, createPatients } from "./Index";
import { useSelector, useDispatch } from "react-redux";
import SpinnerComponent from "./../subcomponents/spinner/SpinnerComponent";
import ToasterComponenet from "./../subcomponents/toaster/ToasterComponent";
import { logout, setToastr } from "./../login/Index";

import "./Dashboard.css";
import { useNavigate } from "react-router-dom";

export const Dashboard = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [childData, setChildData] = useState(""); //selected Row from patient-list table
  const [epilepsyData, setEpilepsyData] = useState([]); //filtered data for epilepsy-history
  const [showTab, setShowTab] = useState(false);
  const [invalid, setInvalid] = useState("");

  const navigate = useNavigate();
  const dashboardProps = useSelector(
    (state) => state.rootReducer1.dashboarReducer
  );
  const patientInfoObj = {
    consent: "",
    age: "",

    emailId: "",
    gender: "",
    height: "",
    mobileNumber: "",
    patientId: "",
    patientName: "",
    race: "",
    status: "ACTIVE",
    weight: "",
    clinic: dashboardProps.siteSelected,
  };
  const [selectedObj, setSelectedObj] = useState(patientInfoObj);
  const url = useSelector(
    (state) => state.rootReducer1.changeTheNumber.serverBaseUrl
  );
  const bottomContainer = {
    allergies: "",
    avgSeizuresPerMonth: "",
    avgSleepCycle: "0.00hrs",
    dose: "",
    id: "",
    form: "",
    count: "",
    frequency: "",

    lastTaken: "",
    laterality: "",
    medicationName: "",
    otherMedInfo: "",
    presciptionId: "",
    typesOfSeizures: "",
    visitNo: dashboardProps.siteSelected,
    yearOfDiagnosis: "",
    yearsWEpilepsy: "",
  };
  const [createPatientObj, setCreatePatientObj] = useState({
    patientInfo: patientInfoObj,
    epilepsyHistory: [bottomContainer],
  });
  const [toasterObj, setToasterObj] = useState({
    show: false,
    delay: 3000,
    onClose: false,
    header: "",
    message: "",
  });
  const siteSelected = useSelector(
    (state) => state.rootReducer1.dashboarReducer.siteSelected
  );
  const jwtToken = useSelector(
    (state) => state.rootReducer1.loginReducer.jwtToken
  );
  const patientsData = useSelector(
    (state) => state.rootReducer1.dashboarReducer.patientsData
  );

  const patientId = useSelector(
    (state) => state.rootReducer1.dashboarReducer.patientId
  );

  const logoutHandler = () => {
    sessionStorage.removeItem("loginTime");
    sessionStorage.setItem("validateOTPResponse", "");
    sessionStorage.setItem("sites", "");
    sessionStorage.clear();
    navigate("/login");
    dispatch(logout(""));
  };

/**
   * This function calculate Remaining time for login session
   */


  const calRemainingTime = () => {
    var currentTime = new Date().getTime();
    const modifiedExpiryTime = sessionStorage.getItem("loginTime");

    if (modifiedExpiryTime && currentTime - modifiedExpiryTime > 10800000) {
      /* 10800000 =3hrs */

      logoutHandler();
    } else {
      sessionStorage.setItem("loginTime", new Date().getTime());
    }
  };

  //after getting the expiryTime
  useEffect(() => {
    calRemainingTime();
  }, []);

  const historyToDashboard = (epilepsyHistoryData) => {
    setCreatePatientObj({
      ...createPatientObj,
      epilepsyHistory: epilepsyHistoryData,
    });
  };
  useEffect(() => {
    setSelectedObj({ ...patientInfoObj, clinic: dashboardProps.siteSelected });
  }, [dashboardProps && dashboardProps.siteSelected]);

  const otherMedInfo = (epilepsyHistoryArray) => {
   
    var _createPatientObj = createPatientObj;
    const length = epilepsyHistoryArray.length;

    if (
      epilepsyHistoryArray[length - 1] &&
      epilepsyHistoryArray[length - 1].otherMedInfo
    ) {
      _createPatientObj.epilepsyHistory[length - 1].otherMedInfo =
        epilepsyHistoryArray[length - 1].otherMedInfo;

      setCreatePatientObj({ ..._createPatientObj });
    }
  };
  const passChildData = (passChildData) => {
    setCreatePatientObj({ ...createPatientObj, patientInfo: passChildData });
  };

  useEffect(() => {
    if (jwtToken !== "" && jwtToken !== undefined && jwtToken !== null) {
      dispatch(getPatients({ jwtToken, siteSelected }));
      calRemainingTime();
      getPatientIDRequest(jwtToken);
    }
  }, [jwtToken,siteSelected]);

  /* useEffect(() => {
    if (siteSelected !== "") {
      dispatch(getPatients({ jwtToken, siteSelected }));
      calRemainingTime();
      getPatientIDRequest(jwtToken);
    }
  }, [siteSelected]); */

  useEffect(() => {
    patientsData &&
      patientsData.forEach((element, index) => {
        if (element.patientInfo.patientId == childData) {
          setSelectedObj(element.patientInfo);
          setEpilepsyData(element.epilepsyHistory);
          const epilepsyHistoryArray = [];
          element.epilepsyHistory &&
            element.epilepsyHistory.forEach((element1) => {
              epilepsyHistoryArray.push(element1);
            });
          var bottomContainerObject = bottomContainer;
          var avgSleepCycle = "0.0hrs";
          if (
            epilepsyHistoryArray[0] &&
            epilepsyHistoryArray[0].avgSleepCycle
          ) {
            avgSleepCycle = epilepsyHistoryArray[0].avgSleepCycle;
          }
          bottomContainerObject.avgSleepCycle = avgSleepCycle;

          epilepsyHistoryArray.push(bottomContainerObject);

          setEpilepsyData(epilepsyHistoryArray);
        }
      });
  }, [jwtToken, childData]);

  const getPatientIDRequest = (jwtToken) => {
    getPatientId(jwtToken);
  };
  const createPatient = (action) => {
    setInvalid(" ");

    if (action === "add") {
      setChildData("");
      setSelectedObj(patientInfoObj);

      setEpilepsyData([bottomContainer]);
      if (jwtToken) {
        getPatientIDRequest(jwtToken);
        dispatch(getPatients(jwtToken));
        calRemainingTime();
      }
    } else if (action === "edit") {
      const dummyCreatePatientObj = {
        patientInfo: selectedObj,
        epilepsyHistory: [bottomContainer],
      };
      for (const [key, value] of Object.entries(
        dummyCreatePatientObj.patientInfo
      )) {
        for (const [key1, value1] of Object.entries(
          createPatientObj.patientInfo
        )) {
          if (
            key1 === key &&
            key !== "race" &&
            key !== "emailId" &&
            key !== "patientId" &&
            key !== "status" &&
            key !== "clinic"
          ) {
            setInvalid(" ");
            if ("" === value1) {
              setInvalid("invalid");

              dispatch(
                setToastr([
                  {
                    show: true,
                    delay: 3000,
                    onClose: false,
                    header: "Invalid form",
                    message: "Please fill required fields.",
                    color: "warning",
                  },
                ])
              );
              setToasterObj({
                show: true,
                delay: 3000,
                onClose: false,
                header: "Invalid form",
                message: "Please fill required fields.",
                color: "warning",
              });
              return;
            }
          }
        }
      }
      let secondDummyCreatePatientObj = {};
      secondDummyCreatePatientObj.patientInfo = createPatientObj.patientInfo;
      secondDummyCreatePatientObj.epilepsyHistory = [];
      createPatientObj.epilepsyHistory &&
        createPatientObj.epilepsyHistory.forEach((element) => {
          secondDummyCreatePatientObj.epilepsyHistory.push(element);
        });
      if (
        secondDummyCreatePatientObj.epilepsyHistory[
          secondDummyCreatePatientObj.epilepsyHistory.length - 1
        ].medicationName === ""
      ) {
        secondDummyCreatePatientObj.epilepsyHistory.splice(-1);
      } else {
        for (
          let i = 0;
          i <
          (secondDummyCreatePatientObj.epilepsyHistory &&
            secondDummyCreatePatientObj.epilepsyHistory.length);
          i++
        ) {
          for (
            let j = 0;
            j <
            (dummyCreatePatientObj.epilepsyHistory &&
              dummyCreatePatientObj.epilepsyHistory.length);
            j++
          ) {
            const patientObj = secondDummyCreatePatientObj.epilepsyHistory[i];
            const dummyPatientObj = dummyCreatePatientObj.epilepsyHistory[j];
            for (const [key, value] of Object.entries(patientObj)) {
              for (const [key1, value1] of Object.entries(dummyPatientObj)) {
                if (
                  key1 == key &&
                  key != "otherMedInfo" &&
                  key != "visitNo" &&
                  key != "id" &&
                  /*   key != "laterality" && */
                  key != "allergies" &&
                  key != "avgSleepCycle" &&
                  /*   key != "form" && 
                  key != "count" &&
                  key != "frequency" && */

                  key != "typesOfSeizures"
                ) {
                  if (value == value1) {
                    setInvalid("invalid");
                    dispatch(
                      setToastr([
                        {
                          show: true,
                          delay: 3000,
                          onClose: false,
                          header: "Invalid form",
                          message: "Please fill required fields.",
                          color: "warning",
                        },
                      ])
                    );
                    setToasterObj({
                      show: true,
                      delay: 3000,
                      onClose: false,
                      header: "Invalid form",
                      message: "Please fill required fields.",
                      color: "warning",
                    });
                    return;
                  }
                }
              }
            }
          }
        }
      }
      editPatientsDetails(jwtToken, createPatientObj);
    } else if (action === "create") {
      const dummyCreatePatientObj = {
        patientInfo: selectedObj,
        epilepsyHistory: [bottomContainer],
      };

      for (const [key, value] of Object.entries(
        dummyCreatePatientObj.patientInfo
      )) {
        for (const [key1, value1] of Object.entries(
          createPatientObj.patientInfo
        )) {
          if (
            key1 == key &&
            key != "race" &&
            key != "emailId" &&
            key != "patientId" &&
            key != "status" &&
            key != "clinic"
          ) {
            if (value == value1) {
              setInvalid("invalid");

              dispatch(
                setToastr([
                  {
                    show: true,
                    delay: 3000,
                    onClose: false,
                    header: "Invalid form",
                    message: "Please fill required fields.",
                    color: "warning",
                  },
                ])
              );
              setToasterObj({
                show: true,
                delay: 3000,
                onClose: false,
                header: "Invalid form",
                message: "Please fill required fields.",
                color: "warning",
              });
              return;
            }
          }
        }
      }

      let secondDummyCreatePatientObj = {};
      secondDummyCreatePatientObj.patientInfo = createPatientObj.patientInfo;
      secondDummyCreatePatientObj.epilepsyHistory = [];
      createPatientObj.epilepsyHistory &&
        createPatientObj.epilepsyHistory.forEach((element) => {
          secondDummyCreatePatientObj.epilepsyHistory.push(element);
        });
      if (
        secondDummyCreatePatientObj.epilepsyHistory[
          secondDummyCreatePatientObj.epilepsyHistory.length - 1
        ].medicationName === ""
      ) {
        secondDummyCreatePatientObj.epilepsyHistory.splice(-1);
      } else {
        for (
          let i = 0;
          i <
          (secondDummyCreatePatientObj.epilepsyHistory &&
            secondDummyCreatePatientObj.epilepsyHistory.length);
          i++
        ) {
          for (
            let j = 0;
            j <
            (dummyCreatePatientObj.epilepsyHistory &&
              dummyCreatePatientObj.epilepsyHistory.length);
            j++
          ) {
            const patientObj = secondDummyCreatePatientObj.epilepsyHistory[i];
            const dummyPatientObj = dummyCreatePatientObj.epilepsyHistory[j];
            for (const [key, value] of Object.entries(patientObj)) {
              for (const [key1, value1] of Object.entries(dummyPatientObj)) {
                if (
                  key1 == key &&
                  key != "otherMedInfo" &&
                  key != "visitNo" &&
                  key != "id" &&
                  /*  key != "laterality" && */
                  key != "form" &&
                  /*  key != "count" &&
                  key != "frequency" && */
                  key != "allergies" &&
                  key != "avgSleepCycle" &&
                  key != "typesOfSeizures"
                ) {
                  if (value == value1) {
                    setInvalid("invalid");

                    dispatch(
                      setToastr([
                        {
                          show: true,
                          delay: 3000,
                          onClose: false,
                          header: "Invalid form",
                          message: "Please fill required fields.",
                          color: "warning",
                        },
                      ])
                    );
                    setToasterObj({
                      show: true,
                      delay: 3000,
                      onClose: false,
                      header: "Invalid form",
                      message: "Please fill required fields.",
                      color: "warning",
                    });
                    return;
                  }
                }
              }
            }
          }
        }
      }
      // dispatch
      createPatients(jwtToken, secondDummyCreatePatientObj);
    }
  };
  const createPatients = (jwtToken, createPatientObj) => {
    {
      setLoading(true);
      try {
        var create = createPatientObj;

        create.mobileNumber = "+91" + createPatientObj.mobileNumber;
        if (dashboardProps.siteSelected !== "all") {
          create.patientInfo.clinic = dashboardProps.siteSelected;
        }
        if (create.epilepsyHistory.length > 0) {
          create.epilepsyHistory[0].visitNo = 1;
        }
        fetch(url + "addPatient", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify(create),
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status == "SUCCESS") {
              setToasterObj({
                show: true,
                delay: 3000,
                onClose: false,
                header: json.status,
                message: json.message,
                color: "success",
              });
              dispatch(
                setToastr([
                  {
                    show: true,
                    delay: 3000,
                    onClose: false,
                    header: json.status,
                    message: json.message,
                    color: "success",
                  },
                ])
              );
              setChildData("");
              setSelectedObj(patientInfoObj);
              setEpilepsyData([bottomContainer]);
              dispatch(getPatients({ jwtToken, siteSelected }));
              calRemainingTime();
              getPatientIDRequest(jwtToken);
              setLoading(false);
              getPatientId(jwtToken);
            } else {
              dispatch(
                setToastr([
                  {
                    show: true,
                    delay: 3000,
                    onClose: false,
                    header: json.message,
                    message: json.data,
                    color: "danger",
                  },
                ])
              );
              setToasterObj({
                show: true,
                delay: 3000,
                onClose: false,
                header: json.message,
                message: json.data,
                color: "danger",
              });
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            throw error;
          });
      } catch (err) {
        setLoading(false);
        dispatch(
          setToastr([
            {
              show: true,
              delay: 3000,
              onClose: false,
              header: "Network error",
              message: "Please try again later.",
              color: "danger",
            },
          ])
        );
        setToasterObj({
          show: true,
          delay: 3000,
          onClose: false,
          header: "Network error",
          message: "Please try again later.",
          color: "danger",
        });
      }
    }
  };

  const editPatientsDetails = (jwtToken, createPatientObj) => {
    {
      let editPatientObj = {
        patientInfo: createPatientObj.patientInfo,
        epilepsyHistory: [],
      };
      const index =
        createPatientObj.epilepsyHistory &&
        createPatientObj.epilepsyHistory.length - 1;
      const epilepsyHistory = [];

      if (createPatientObj.epilepsyHistory[index].medicationName !== "") {
        // for (let i = 0; i < index; i++) {
        epilepsyHistory.push(createPatientObj.epilepsyHistory[index]);
        // }
        epilepsyHistory[0].visitNo = index + 1;
        editPatientObj.epilepsyHistory = epilepsyHistory;
      }

      // return;
      setLoading(true);
      try {
        fetch(url + "patients", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify(editPatientObj),
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status == "SUCCESS") {
              setToasterObj({
                show: true,
                delay: 3000,
                onClose: false,
                header: json.status,
                message: json.message,
                color: "success",
              });
              dispatch(
                setToastr([
                  {
                    show: true,
                    delay: 3000,
                    onClose: false,
                    header: json.status,
                    message: json.message,
                    color: "success",
                  },
                ])
              );
              setChildData("");
              setSelectedObj(patientInfoObj);
              setEpilepsyData([bottomContainer]);
              getPatientIDRequest(jwtToken);
              setLoading(false);
              dispatch(getPatients({ jwtToken, siteSelected }));
              calRemainingTime();
            } else {
              setToasterObj({
                show: true,
                delay: 3000,
                onClose: false,
                header: json.message,
                message: json.data,
                color: "danger",
              });
              dispatch(
                setToastr([
                  {
                    show: true,
                    delay: 3000,
                    onClose: false,
                    header: json.message,
                    message: json.data,
                    color: "danger",
                  },
                ])
              );
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            throw error;
          });
      } catch (err) {
        console.log(err);
        setLoading(false);
        setToasterObj({
          show: true,
          delay: 3000,
          onClose: false,
          header: "Network error",
          message: "Please try again later.",
          color: "danger",
        });
        dispatch(
          setToastr([
            {
              show: true,
              delay: 3000,
              onClose: false,
              header: "Network error",
              message: "Please try again later.",
              color: "danger",
            },
          ])
        );
      }
    }
  };
  const getPatientId = (jwtToken) => {
    {
      setLoading(true);
      try {
        fetch(url + "createPatientId", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          // body: JSON.stringify(createPatientObj),
        })
          .then((response) => response.json())
          .then((json) => {
            setLoading(false);
            if (json.status == "SUCCESS") {
              // setToasterObj({
              //   show: true,
              //   delay: 3000,
              //   onClose: false,
              //   header: json.status,
              //   message: json.message,
              //   color: "success",
              // });
              setChildData("");
              /* setSelectedObj(patientInfoObj);  */
              setEpilepsyData([bottomContainer]);

              setSelectedObj({
                ...patientInfoObj,
                patientId: json.data.patientId,
              });

              // setShowToastr(true);
            } else {
              setToasterObj({
                show: true,
                delay: 3000,
                onClose: false,
                header: json.status,
                message: json.message,
                color: "danger",
              });
              dispatch(
                setToastr([
                  {
                    show: true,
                    delay: 3000,
                    onClose: false,
                    header: json.status,
                    message: json.message,
                    color: "danger",
                  },
                ])
              );
            }
          })
          .catch((error) => {
            setLoading(false);

            throw error;
          });
      } catch (err) {
        console.log(err);
        setLoading(false);
        setToasterObj({
          show: true,
          delay: 3000,
          onClose: false,
          header: "Network error",
          message: "Please try again later.",
          color: "danger",
        });
        dispatch(
          setToastr([
            {
              show: true,
              delay: 3000,
              onClose: false,
              header: "Network error",
              message: "Please try again later.",
              color: "danger",
            },
          ])
        );
      }
    }
  };
  const returnShowTab = (e) => {
    setShowTab(e);
  };

  return (
    <div style={{ height: "90vh!important", overflowY: "scroll" }}>
      <div className="content">
        {(dashboardProps && dashboardProps.loading) ||
          (loading && <SpinnerComponent data-testid="spinner" className="dashboard-spinner" />)}
        <div className="left-col">
          <div className="item1">
            <ParticipantInfromationComponent
              selectedRow={selectedObj}
              data={patientsData}
              passChildData={passChildData}
              patientId={patientId}
              invalidForm={invalid}
            />
          </div>

          <div className="item2">
            <EpilepsyHistoryComponent
              historyToDashboard={historyToDashboard}
              data={epilepsyData}
              showTab1={showTab}
              returnShowTab={returnShowTab}
              invalidForm={invalid}
            />
          </div>
          <div className="item3">
            <OtherMedicalInformationComponent
              data={epilepsyData}
              otherMedInfo={otherMedInfo}
              createPatient={createPatient}
              selectedPatientId={childData}
              showTab1={showTab}
              returnShowTab={returnShowTab}
            />
          </div>
        </div>

        <div className="right-col">
          <div className="item5">
            <ParticipantListComponent
              passChildData={setChildData}
              patientsData={patientsData}
              patientId1={selectedObj.patientId}
            />
          </div>
          <div className="item6">
            <ParticipantDetailsComponent patientData={patientsData} />
          </div>
        </div>
      </div>
    </div>
  );
};
