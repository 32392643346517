import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./OtherMedicalInformationComponent.css";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { setToastr } from "../../../login/Index";
import { useDispatch } from "react-redux";

export const OtherMedicalInformationComponent = (props) => {
  const [formVal, setFormVal] = useState([]);
  const [visitSelected, setVisitSelected] = useState(0);
  const [selectedPatientId, setSelectedPatientId] = useState("");

  const dispatch = useDispatch();
/**
 * This function is used to set Visit and form data.
 */
  useEffect(() => {
    if (props.data != null) {
      setFormVal(props.data);
      
      setVisitSelected(0)
 
    }
  }, [props && props.data]);

  /**
   * Use to set patient id from dashboard on selected patient change in right side of table.
   */
  useEffect(() => {
    setSelectedPatientId(props.selectedPatientId);
  }, [props.selectedPatientId]);

  /**
   * Use to return the form value of other medical information on change
   */
  useEffect(() => {
    props.otherMedInfo(formVal);
  }, [formVal]);

  /**
   * This function is used to create patient
   * @param {*} data
   */
  const createPatient = (data) => {
    props.createPatient(data);
    console.log(data);
  };
  return (
    <Card className="form">
      <Card.Header className="form_header">
        <Row>
          <Col>
            <h1> OTHER MEDICAL INFORMATION</h1>
          </Col>
        </Row>
      </Card.Header>
      <Form>
        <div className="form_tabs " data-testid="page-tabs">
          <ul className="form_tabs_ul">
            {" "}
            {formVal &&
              formVal.map((e, index) => {
                return (
                  <div key={"visit" + index}>
                    {index + 1 < formVal.length ? (
                      <li
                        style={{ cursor: "pointer" }}
                        className={`${
                          visitSelected == index ? "selectedTab" : ""
                        }`}
                        key={index}
                        onClick={(e) => {
                          setVisitSelected(index);
                        }}
                      >
                        <a> Visit {index + 1} </a>
                      </li>
                    ) : (
                      <>
                        {formVal.length === 1 ? (
                          <>
                            {" "}
                            <li
                              style={{ cursor: "pointer" }}
                              className={`${
                                visitSelected == index ? "selectedTab" : ""
                              }`}
                              key={"a" + index}
                              onClick={(e) => {
                                setVisitSelected(index);
                              }}
                            >
                              <a> Visit {index + 1} </a>
                            </li>
                            <li
                              style={{
                                cursor: "pointer",
                                color: "rgb(146, 146, 151)",
                              }}
                              disabled
                              key={index}
                            >
                              <a> + </a>
                            </li>
                          </>
                        ) : (
                          <li
                            style={{
                              cursor: "pointer",
                              color:
                                formVal.length === 1
                                  ? "rgb(146, 146, 151)"
                                  : "black",
                            }}
                            className={`${
                              visitSelected == index ? "selectedTab" : ""
                            }`}
                            key={index}
                            onClick={(e) => {
                              setVisitSelected(index);
                            }}
                          >
                            <a> + </a>
                          </li>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
          </ul>
        </div>

        {formVal &&
          formVal.map((e, index) => {
            if (index === visitSelected) {
              return (
                <div key={index + 100}>
                  <Card.Body style={{ paddingBottom: "0px" }}>
                    <div className="form_subheading ">
                      <img src="./images/pill_icon.png" alt="" />
                      <h2> Medication Condition & History</h2>
                    </div>

                    <div>
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Notes"
                        className={
                          index < formVal.length - 1
                            ? "mb-3 input_textarea_disable "
                            : "mb-3 input_textarea "
                        }
                      >
                        <Form.Control
                        //onClick={ onClickHandler }
                        //  onBlur={onBlurHandler}
                          name="otherMedInfo"
                          value={e.otherMedInfo}
                          key="otherMedInfo"
                          maxLength="250"
                          disabled={index < formVal.length - 1}
                          style={{
                            border: index < formVal.length - 1 && "none",
                            backgroundColor:
                              index < formVal.length - 1 &&
                              "rgba(43, 102, 241, 0.15)",
                            height: "48px",
                          }}
                          onKeyUp={(e) => {
                            e.preventDefault();
                            if (e.target.value.length >= 250) {
                              dispatch(
                                setToastr([
                                  {
                                    show: true,
                                    delay: 3000,
                                    onClose: false,
                                    header: "Warning",
                                    message: "Maximum character count reached.",
                                    color: "warning",
                                  },
                                ])
                              );
                            }
                          }}
                          as="textarea"
                          type="text"
                          placeholder="Leave a comment here"
                          data-testid="notes-input"
                          onChange={(e) => {
                            const otherMedInfo = e.target.value;
                            setFormVal((currentForm) =>
                              currentForm.map((x, index1) =>
                                index1 == visitSelected
                                  ? { ...x, otherMedInfo }
                                  : x
                              )
                            );
                          }}
                        />
                      </FloatingLabel>
                    </div>

                    <div className="action_buttons">
                      {selectedPatientId == undefined ||
                      selectedPatientId == "" ? (
                        <Button
                          data-testid="savePatient-btn"
                          className="m-2"
                          variant="primary"
                          onClick={() => {
                            createPatient("create");
                          }}
                        >
                          Save Patient
                        </Button>
                      ) : (
                        <>
                          <Button
                            className="m-2"
                            variant="primary"
                            onClick={(e) => {
                              e.preventDefault();
                              createPatient("add");
                            }}
                          >
                            Add Patient
                          </Button>

                          <Button
                            role="button"
                            className="m-2"
                            variant="primary"
                            onClick={(e) => {
                              e.preventDefault();
                              createPatient("edit");
                            }}
                          >
                            Save Protocol
                          </Button>
                        </>
                      )}
                    </div>
                  </Card.Body>
                </div>
              );
            }
          })}
      </Form>
    </Card>
  );
};
