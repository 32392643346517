export const getPatientID = (payload) => {
  return {
    type: "GET_PATIENT_ID_REQUESTED",
    payload: payload,
  };
};
export const getPatients = (payload) => {
  return {
    type: "GET_PATIENTS_REQUESTED",
    payload: payload,
  };
};
export const createPatients = (payload) => {
  return {
    type: "CREATE_PATIENT_REQUESTED",
    payload: payload,
  };
};
export const setSiteSelected = (payload) => {
  return {
    type: "SET_SITE_SELECTED",
    payload: payload,
  };
};
export const setPatientIdSelected = (payload) => {
  return {
    type: "SET_PATIENT_ID_SELECTED",
    payload: payload,
  };
};

