import { all } from "redux-saga/effects";
import { watcherAPISaga1, watcherValidateOTP } from "../components/login/Saga";
import {
  watcherGetPatientId,
  watcherGetPatients,
  watcherCreatePatient
} from "../components/dashboard/Saga";
export default function* rootSaga() {
  yield all([
    watcherAPISaga1(),
    watcherValidateOTP(),
    watcherGetPatientId(),
    watcherGetPatients(),
    watcherCreatePatient(),
  ]);
}
