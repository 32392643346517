import {
  SEND_OTP_REQUESTED,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILED,
  VALIDATE_OTP_REQUESTED,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_FAILED,
} from "./Type";
import produce from "immer";
import React, { useEffect, useState } from "react";
const initialState = {
  sendOTPResponse: {},
  sendOTPflag: false,
  validateOTPResponse: {},
  authenticateOTPResponse: {},
  loading: false,
  error: null,
  jwtToken: "",
  isAuthenticated: false,
  toastrObj: [
    {
      show: false,
      delay: 3000,
      onClose: false,
      header: "Success",
      message: "Success",
      color: "success",
    },
  ],
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_OTP_REQUESTED:
      return { ...state, loading: true, sendOTPflag: false };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        sendOTPResponse: action.sendOTPResponse,
        sendOTPflag: true,
      };
    case SEND_OTP_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
        sendOTPflag: false,
      };

    case VALIDATE_OTP_REQUESTED:
      return { ...state, loading: true };
    case VALIDATE_OTP_SUCCESS:
      
      return {
        ...state,
        loading: false,
        validateOTPResponse: action.validateOTPResponse.data.loginDetails,
        jwtToken: action.validateOTPResponse.data.jwtToken,
        isAuthenticated: true,
      };
    case VALIDATE_OTP_FAILED:
      return {
        ...state,
        loading: false,
        jwtToken: "",
        error: action.message,
        isAuthenticated: false,
      };
    case "SAVEJWT":
      return { ...state, jwtToken: action.payload };

    case "LOGOUT":
      const nextState1 = produce(state.toastrObj, (draftState) => {
        // "mutate" the draft array
        // draftState.push({ todo: "Tweet about it" });
        // "mutate" the nested state
        draftState[0] = [
          {
            show: false,
            delay: 3000,
            onClose: false,
            header: "Success",
            message: "Logged out successfully.",
            color: "success",
          },
        ];
      });
      return { toastrObj: nextState1, jwtToken: "", state: initialState };
    case "SET_TOASTR":
      // const toastrObj1 = action.payload[0];
      const nextState = produce(state.toastrObj, (draftState) => {
        // "mutate" the draft array
        // draftState.push({ todo: "Tweet about it" });
        // "mutate" the nested state
        draftState[0] = action.payload[0];
      });
      return { ...state, toastrObj: nextState };
    default:
      return state;
  }
};

export default loginReducer;
