
import "./App.css";

import { Route, Routes, Navigate } from "react-router-dom";
import DashboardContainer from "./components/dashboardContainer/DashboardContainer";
import Login from "./components/login/Login";
import "bootstrap/dist/css/bootstrap.min.css";

import ToasterComponent from "./../src/components/subcomponents/toaster/ToasterComponent";

export const App = () => {

  return (
    <>
      <div>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />}></Route>
          <Route path="/login" element={<Login />}></Route>
   
          <Route path="*" element={<DashboardContainer />}></Route>
        </Routes>
      </div>
      <div>
        <ToasterComponent />
      </div>
    </>
  );
};

export default App;
