import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./ParticipantInformationComponent.css";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { setToastr } from "../../../login/Index";

export const ParticipantInfromationComponent = (props) => {

  let sites = JSON.parse(sessionStorage.getItem("sites"));
  sites.shift();

  const [isSuperUser, setIsSuperUser] = useState(false);
  const [mobErrorMsg, setMobErrorMsg] = useState("");

  const dispatch = useDispatch();
  const siteSelected = useSelector(
    (state) => state.rootReducer1.dashboarReducer.siteSelected
  );

  const [formVal, setFormVal] = useState();

  const {
    register,
    formState: { errors },
    clearErrors,
    trigger,
  } = useForm(props && props.selectedRow);

  /**
   * This function is used to pass data to parent component (Dashboard)
   * @param {*} formVal
   */
  const passChildData = (formVal) => {
    props.passChildData(formVal);
  };

  useEffect(() => {
    passChildData(formVal);
  }, [formVal]);
  useEffect(() => {
    if (props.invalidForm == "invalid") {
      for (const [key, value] of Object.entries(formVal)) {
        if (key !== "patientId" && value === "") trigger(key);
      }

      setMobErrorMsg("Required mobile number");
    } else {
      clearErrors();
    }
  }, [props && props.invalidForm]);

  useEffect(() => {
    getRole();

    let row = props.selectedRow;

    if (row.dob) {
      row.dob = new Date(row.dob).toISOString().split("T")[0];
    }
    /* if(row.mobileNumber){
      row.mobileNumber = row.mobileNumber.replace("+91","") //UNcomment at the end of project
      console.log("row.mobileNumber",row.mobileNumber)
    } */
    // row.dob
    //write logic for mobile number sepearate +91

    clearErrors();
    setFormVal(row);
    getRole();
  }, [props && props.selectedRow]);


  /**
   * This function is used to get the role of login user
   */
  const getRole = () => {
    const roleObj = JSON.parse(sessionStorage.getItem("validateOTPResponse"));
    let role = roleObj && roleObj.roles && roleObj.roles[0].name;

    if (role === "all") {
      setIsSuperUser(true);
    } else {
      setIsSuperUser(false);
    }
  };
  /**
   * This function executed on form value change
   * @param {*} e
   */
  const handleInputChange = (e) => {
    let value = e.target.value;
    let key = e.target.name;

    if (key === "mobileNumber") {
      value = value
        .replace(/[a-zA-Z!@#{}/<>/[//?/|\$%\^\&*\;:"' )\(=._-]/, "")
        .slice(0, 13);
      if (value.length === 0) {
        setMobErrorMsg("Required mobile number");
      } 
      // for both +1 and +91 
      
      else if (value.length === 13 || value.length === 12) {
        setMobErrorMsg("");

        clearErrors(key);



      } 
      
      //for +1 number
      /* else if (value.length === 12) {
        setMobErrorMsg("");

        clearErrors(key);
      } */
      
      
      else {
        setMobErrorMsg("Invalid mobile number");
        trigger(key);
      }

      setFormValue(key, value);
    } else if (key === "patientName") {
      if (e.target.value.length >= 50) {
        dispatch(
          setToastr([
            {
              show: true,
              delay: 3000,
              onClose: false,
              header: "Warning",
              message: "Maximum character count reached.",
              color: "warning",
            },
          ])
        );
      }
      if (value !== "") {
        clearErrors(e.target.name);
      } else {
        trigger(key);
      }
      setFormValue(key, value);
    } else if (key === "height") {
      if (e.target.value.length > 3) {
        dispatch(
          setToastr([
            {
              show: true,
              delay: 3000,
              onClose: false,
              header: "Warning",
              message: "Maximum 3 digits allowed.",
              color: "warning",
            },
          ])
        );
      }
      value = value
        .replace(/[a-zA-Z!@#\$%{}|/+.,/</>[\^\&*\;:"'+ )\(=._-]/, "")
        .slice(0, 3);
      if (value !== "") {
        clearErrors(e.target.name);
      } else {
        trigger(key);
      }
      setFormValue(key, value);
    } else if (key === "age") {
      if (e.target.value.length >= 4) {
        dispatch(
          setToastr([
            {
              show: true,
              delay: 3000,
              onClose: false,
              header: "Warning",
              message: "Maximum 3 digits allowed.",
              color: "warning",
            },
          ])
        );
      }
      value = value.slice(0, 3);
      if (value !== "") {
        clearErrors(e.target.name);
      } else {
        trigger(key);
      }
      setFormValue(key, value);
    } else if (key === "clinic") {
      if (value !== "") {
        clearErrors(e.target.name);
      } else {
        trigger(key);
      }
      setFormValue(key, value);
    } else if (key === "weight") {
      if (e.target.value.length > 3) {
        dispatch(
          setToastr([
            {
              show: true,
              delay: 3000,
              onClose: false,
              header: "Warning",
              message: "Maximum 3 digits allowed.",
              color: "warning",
            },
          ])
        );
      }
      value = value
        .replace(/[a-zA-Z!@#\$%{}|/+./</>[\^\&*\;:"'+ )\(=._-]/, "")
        .slice(0, 3);
      if (value !== "") {
        clearErrors(e.target.name);
      } else {
        trigger(key);
      }
      setFormValue(key, value);
    } else {
      setFormValue(key, value);

      if (e.target.value != "") {
        clearErrors(e.target.name);
      } else {
        trigger(e.target.name);
      }
    }
  };

  /**
   * This function is used to set form values using key and value
   * @param {*} key
   * @param {*} value
   */
  const setFormValue = (key, value) => {
    setFormVal({
      ...formVal,
      [key]: value,
    });

    props.passChildData(formVal);
  };

  return (
    <Card className="form">
      <Card.Header className="form_header">
        <Row>
          <Col>
            <h1> PARTICIPANT INFORMATION</h1>
          </Col>
        </Row>
      </Card.Header>

      {formVal && (
        <Card.Body>
          <Form  autoComplete="off">
            <div className="form_subheading ">
              <img src="./images/pill_icon.png" alt="" />
              <h2> Patient Detail</h2>
            </div>

            <Row className="g-3">
              <Col md={4}>
                <FloatingLabel controlId="floatingInputGrid" label="Patient ID">
                  <Form.Control
                    style={{
                      border: "none",
                      backgroundColor: "rgba(43, 102, 241, 0.15)",
                    }}
                    defaultValue={formVal.patientId}
                    type="text"
                    placeholder="patientId"
                    data-testid="patientID-input"
                    name="patientId"
                    {...register("patientId", {
                      required: " Required",
                    })}
                    onClick={async () => {
                      await trigger("patientId");
                    }}
                    onChange={handleInputChange}
                    disabled
                  />
                </FloatingLabel>
                {errors.patientId && (
                  <h2 className="form-text text-danger invalid-text">
                    {errors.patientId.message}
                  </h2>
                )}
              </Col>

              <Col md={4}>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Patient Name"
                >
                  <Form.Control
                    value={formVal.patientName}
                    name="patientName"
                    maxLength="50"
                    type="text"
                    placeholder="Patient Name"
                    data-testid="patientName-input"
                    inputRef={register("patientName", {
                      required: "Required patient name",
                      minLength: {
                        value: 50,
                        message: "Maximum 50 characters allowed.",
                      },
                    })}
                    onClick={handleInputChange}
                    onChange={handleInputChange}
                  />
                </FloatingLabel>
                {errors.patientName && (
                  <h2 className="form-text text-danger invalid-text">
                    {errors.patientName.message}
                  </h2>
                )}
              </Col>

              <Col>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Mobile Number"
                >
                  <Form.Control
                    value={formVal.mobileNumber}
                    type="text"
                    name="mobileNumber"
                    placeholder="Mobile Number"
                    data-testid="mobnum-input"
                    inputRef={register("mobileNumber", {
                      required: "Required mobile number",
                    })}
                    onClick={handleInputChange}
                    onChange={handleInputChange}
                  />
                </FloatingLabel>

                {errors.mobileNumber && (
                  <h2 className="form-text text-danger invalid-text">
                    {mobErrorMsg}
                  </h2>
                )}
              </Col>
            </Row>

            <Row
              className="g-3"
              style={{ marginTop: "8px", "--bs-gutter-y": "0" }}
            >
              <Col md={4}>
                <FloatingLabel
                  controlId="floatingSelectGrid"
                  label="Consent Provided & Signed"
                >
                  <Form.Select
                    value={formVal.consent}
                    data-testid="consent"
                    name="consent"
                    aria-label="Floating label select example"
                    inputRef={register("consent", {
                      required: "Please select consent",
                    })}
                    onClick={handleInputChange}
                    onChange={handleInputChange}
                  >
                    <option value="" hidden>
                      Select...
                    </option>

                    <option value="Yes, patient can participate">
                      Yes, patient can participate
                    </option>
                    <option value="No, patient cannot participate">
                      No, patient cannot participate
                    </option>
                  </Form.Select>
                </FloatingLabel>
                {errors.consent && (
                  <h2 className="form-text text-danger invalid-text">
                    {errors.consent.message}
                  </h2>
                )}
              </Col>

              <Col>
                <FloatingLabel controlId="floatingInputGrid" label="Age">
                  <Form.Control
                    value={formVal.age}
                    data-testid="age"
                    name="age"
                    inputRef={register("age", {
                      required: "Required age",
                    })}
                    type="number"
                    placeholder="Age"
                    onChange={handleInputChange}
                    onClick={handleInputChange}
                  />
                </FloatingLabel>
                {errors.age && (
                  <h2 className="form-text text-danger invalid-text">
                    {errors.age.message}
                  </h2>
                )}
              </Col>

              <Col md={4}>
                <FloatingLabel controlId="floatingSelectGrid" label="Gender">
                  <Form.Select
                    data-testid="gender"
                    aria-label="Floating label select example"
                    inputRef={register("gender", {
                      required: "Please select gender",
                    })}
                    value={formVal.gender}
                    name="gender"
                    onClick={handleInputChange}
                    onChange={handleInputChange}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </Form.Select>
                </FloatingLabel>
                {errors.gender && (
                  <small className="form-text text-danger invalid-text">
                    {errors.gender.message}
                  </small>
                )}
              </Col>

              <Row
                className="g-3"
                style={{
                  marginTop: "8px",

                  "--bs-gutter-y": "0",
                }}
              ></Row>
              <Col md={2}>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Height (cm)"
                >
                  <Form.Control
                    data-testid="height"
                    value={formVal.height}
                    name="height"
                    inputRef={register("height", {
                      required: "Required height",
                    })}
                    type="text"
                    placeholder="Height (cm)"
                    onChange={handleInputChange}
                    onClick={handleInputChange}
                  />
                </FloatingLabel>
                {errors.height && (
                  <h2 className="form-text text-danger invalid-text">
                    {errors.height.message}
                  </h2>
                )}
              </Col>

              <Col md={2}>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Weight (kg)"
                >
                  <Form.Control
                    data-testid="weight"
                    value={formVal.weight}
                    type="number"
                    name="weight"
                    placeholder="Weight (kg)"
                    inputRef={register("weight", {
                      required: "Required weight",
                    })}
                    onClick={handleInputChange}
                    onChange={handleInputChange}
                  />
                </FloatingLabel>
                {errors.weight && (
                  <h2 className="form-text text-danger invalid-text">
                    {errors.weight.message}
                  </h2>
                )}
              </Col>
              <Col md={4}>
                <FloatingLabel
                  controlId="floatingSelectGrid"
                  label="Race (optional)"
                >
                  <Form.Select
                    data-testid="race"
                    value={formVal.race}
                    name="race"
                    aria-label="Floating label select example"
                    onChange={handleInputChange}
                  >
                    <option value="">Select...</option>
                    {[
                      "White",
                      "Black or African American",
                      "American Indian or Alaska Native",
                      "Asian",
                      "Native Hawaiian or Other Pacific Islander",
                      "Other",
                      "More than One Race",
                    ].map((e) => {
                      return (
                        <option value={e} key={e}>
                          {e}
                        </option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
                {errors.race && (
                  <h2 className="form-text text-danger invalid-text">
                    {errors.race.message}
                  </h2>
                )}
              </Col>

              {isSuperUser && siteSelected === "all" && (
                <Col md={4}>
                  <FloatingLabel
                    controlId="floatingSelectGrid"
                    label="Select Clinic"
                  >
                    <Form.Select
                      data-testid="clinic"
                      name="clinic"
                      value={formVal.clinic}
                      aria-label="Floating label select example"
                      inputRef={register("clinic", {
                        required: "Please select clinic",
                      })}
                      onClick={handleInputChange}
                      onChange={handleInputChange}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {/* <option value="site 1">Site 1 </option>
                      <option value="site 2">Site 2</option>
                      <option value="site 3">Site 3</option> */}

                      {
                        sites.map((e) => {
                          return (
                            <option value={e.name} >
                              {e.name.charAt(0).toUpperCase() + e.name.slice(1)}
                            </option>
                          );
                        })
                      }
                    </Form.Select>
                  </FloatingLabel>

                  {errors.clinic && (
                    <h2 className="form-text text-danger invalid-text">
                      {errors.clinic.message}
                    </h2>
                  )}
                </Col>
              )}
            </Row>
          </Form>
        </Card.Body>
      )}
    </Card>
  );
};
